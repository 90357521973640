// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import uuid from 'uuid/v4';

// Components.
import ActionButton from '../../components/ActionButton';
import SearchBar from '../../components/SearchBar';

// Elements.
import GolferList from './elements/GolferList';

// Styles.
import './Golfers.scss';

// Firebase.
import { createGolfer } from '../../firebase/db';

class Golfers extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            golfers: PropTypes.object,
            setLoading: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            golfers: null,
        }
    }

    state = {
        newName: '',
        newNickname: '',
        newHometown: '',
        newGivenHandicap: '',
        failedCreation: null,
        selectedTab: 0,

        search: '',
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Golfers');
    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value });
    }

    createGolfer = e => {

        e.preventDefault();

        this.setState({ failedCreation: null }, () => {

            this.props.setLoading(true);

            const name = this.state.newName.trim();
            const nickname = this.state.newNickname.trim();
            const hometown = this.state.newHometown.trim();
            const givenHandicap = parseInt(this.state.newGivenHandicap);

            if (!(givenHandicap >= 0 && givenHandicap <= 54)) {

                this.setState({ failedCreation: 'Handicap must be between 0 and 54.' });
                this.props.setLoading(false);

                return;

            }

            createGolfer(this.props.user, uuid(), { name, nickname, hometown, givenHandicap }).then(() => {
                this.props.setLoading(false);
                this.reset();
            });

        });

    }

    isCreateGolferReady = () => {
        return (this.state.newName !== '' && this.state.newHometown !== '' && this.state.newGivenHandicap !== '');
    }

    selectTab = selectedTab => {
        this.setState({ selectedTab });
    }

    reset = () => {
        this.setState({
            newName: '',
            newNickname: '',
            newHometown: '',
            newGivenHandicap: '',
            selectedTab: 0,
        });
    }

    onSearchChanged = e => {
        this.setState({ search: e.target.value });
    }

    render() {

        // const {
        //     newName,
        //     newNickname,
        //     newHometown,
        //     newGivenHandicap,
        //     failedCreation,
        //     selectedTab,
        // } = this.state;
        const { search } = this.state;
        const { user, golfers } = this.props;

        if (!user || !golfers) return <Redirect to="/" />;

        return (
            <section className="Golfers">
                {
                    Object.keys(golfers).length > 0 &&
                    <SearchBar onChange={ this.onSearchChanged } value={ search } />
                }
                <GolferList golfers={ golfers } search={ search } />
                <Link to="/golfers/create">
                    <ActionButton icon="create" />
                </Link>
            </section>
        );

    }

}

export default Golfers;
