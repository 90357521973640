// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Components.
import Notification from '../../../components/Notification';
import CourseRow from '../../../components/CourseRow';

// Utils.
import { getTournamentRounds } from '../../../utils/golf';

const ActiveList = ({ rounds, tournaments, listType }) => {

    let singleRounds = null;
    let activeTournaments = null;

    if (listType === 'rounds') {
        singleRounds = Object.entries(rounds).filter(round => {
            return (!round[1].tournament && !round[1].complete);
        }).sort((a, b) => (b[1].date - a[1].date));
    }

    if (listType === 'tournaments') {
        activeTournaments = Object.entries(tournaments).filter(tournament => {
            return (!tournament[1].complete);
        });
    }

    return (
        <div className="ActiveList">
            { listType === 'rounds' && singleRounds.length === 0 && <Notification>There are no rounds in progress.</Notification> }
            {
                listType === 'rounds' &&
                singleRounds.map((singleRound, key) => (
                    <Link to={ `/play/rounds/${ singleRound[0] }` } key={ key }>
                        <CourseRow
                            primary={ singleRound[1].course.details.name }
                            secondary={ moment.unix(singleRound[1].date / 1000).format('dddd, MMMM D, YYYY') }
                        />
                    </Link>
                ))
            }
            { listType === 'tournaments' && activeTournaments.length === 0 && <Notification>There are no tournaments in progress.</Notification> }
            {
                listType === 'tournaments' &&
                activeTournaments.map((tournament, key) => {

                    const tournamentRounds = getTournamentRounds(tournament[0], rounds);

                    return (
                        <Link to={ `/play/tournaments/${ tournament[0] }` } key={ key }>
                            <CourseRow
                                primary={ tournament[1].name }
                                secondary={ `${ tournamentRounds.length } ${ tournamentRounds.length === 1 ? 'Round' : 'Rounds' }` }
                            />
                        </Link>
                    );

                })
            }
        </div>
    );

};

ActiveList.propTypes = {
    rounds: PropTypes.object,
    tournaments: PropTypes.object,
    listType: PropTypes.oneOf(['rounds', 'tournaments']),
};

ActiveList.defaultProps = {
    rounds: null,
    tournaments: null,
    listType: 'rounds',
};

export default ActiveList;
