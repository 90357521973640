// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import ordinal from 'ordinal';

// Styles.
import './StatsList.scss';

// Components.
import Notification from '../../components/Notification';

// Utils.
import { getRoundsForGolfer, getGrossScoreForRound, getRoundDetailsForGolfer } from '../../utils/golf';

const StatLine = props => (
    <div className="StatLine">
        <div className="labels">
            <h4>{ props.header }</h4>
            <div>
                <h4><strong>{ props.stat }</strong></h4>
                <h4>({ ordinal(props.position) })</h4>
            </div>
        </div>
        <div className="bar">
            <div className="fill" style={{ width: `${ ((props.count - props.position + 1) / props.count) * 100 }%` }}></div>
        </div>
    </div>
);

StatLine.propTypes = {
    header: PropTypes.string.isRequired,
    stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    position: PropTypes.number,
    count: PropTypes.number,
};

StatLine.defaultProps = {
    position: 1,
    count: 1,
};

class StatsList extends React.Component {

    static get propTypes() {
        return {
            rounds: PropTypes.object,
            golfers: PropTypes.object.isRequired,
            golfer: PropTypes.object.isRequired,
        }
    }

    static get defaultProps() {
        return {
            rounds: null,
        }
    }

    qualifiedGolfers = isPerRound => {

        const { golfers, rounds } = this.props;

        if (isPerRound) {
            return Object.entries(golfers).filter(golfer => {
                return (getRoundsForGolfer(rounds, golfer[0], true, '-1', true).length >= 1);
            });
        }

        return Object.entries(golfers);

    }

    scoringAverage = () => {

        const { rounds } = this.props;
        const qualifiedGolfers = this.qualifiedGolfers(true);
        const list = [];

        for (let golfer of qualifiedGolfers) {

            const qualifiedRounds = getRoundsForGolfer(rounds, golfer[0], true, '-1', true);
            let sum = 0;

            for (let round of qualifiedRounds) {
                sum += getGrossScoreForRound(round, golfer[0]);
            }

            list.push({
                golferId: golfer[0],
                ...golfer[1],
                statistic: (sum / qualifiedRounds.length).toFixed(2),
            });

        }

        return list.sort((a, b) => (a.statistic - b.statistic));

    }

    lowGross = () => {

        const { rounds } = this.props;
        const qualifiedGolfers = this.qualifiedGolfers(false);
        const list = [];

        for (let golfer of qualifiedGolfers) {

            const qualifiedRounds = getRoundsForGolfer(rounds, golfer[0], true, '-1', true);
            let scores = [];

            for (let round of qualifiedRounds) {
                scores.push(getGrossScoreForRound(round, golfer[0]));
            }

            if (qualifiedRounds.length >= 1) {
                list.push({
                    golferId: golfer[0],
                    ...golfer[1],
                    statistic: Math.min(...scores),
                });
            }

        }

        return list.sort((a, b) => (a.statistic - b.statistic));

    }

    scoreToPar = (score, isAverage = false) => {

        const { rounds } = this.props;
        const qualifiedGolfers = this.qualifiedGolfers(false);
        const list = [];

        for (let golfer of qualifiedGolfers) {

            const qualifiedRounds = getRoundsForGolfer(rounds, golfer[0], true, '-1', true);
            let sum = 0;

            for (let round of qualifiedRounds) {

                const details = getRoundDetailsForGolfer(round, golfer[0]);

                for (let i = 1; i <= 18; i++) {

                    const gross = details.scores[i];
                    const scoreToPar = (gross - round.course.details.tees[round.teeColor].holes[i].par);

                    if (scoreToPar === score) sum++;

                }

            }

            if (qualifiedRounds.length >= 1) {
                list.push({
                    golferId: golfer[0],
                    ...golfer[1],
                    statistic: isAverage ? (sum / qualifiedRounds.length).toFixed(2) : sum,
                });
            }

        }

        return list.sort((a, b) => (b.statistic - a.statistic));

    }

    renderScoringAverage = () => {

        const { golfer } = this.props;
        const scoringAverage = this.scoringAverage();
        const myScoringAverage = scoringAverage.filter(g => g.golferId === golfer.id)[0];
        const index = scoringAverage.findIndex(g => g.golferId === golfer.id);

        return (
            <StatLine
                header="Scoring Average"
                stat={ myScoringAverage.statistic }
                position={ index + 1 }
                count={ scoringAverage.length }
            />
        );

    }

    renderLowGross = () => {

        const { golfer } = this.props;
        const lowGross = this.lowGross();
        const myLowGross = lowGross.filter(g => g.golferId === golfer.id)[0];
        const index = lowGross.findIndex(g => g.golferId === golfer.id);

        return (
            <StatLine
                header="Low Gross"
                stat={ myLowGross.statistic }
                position={ index + 1 }
                count={ lowGross.length }
            />
        );

    }

    renderEagles = isAverage => {

        const { golfer } = this.props;
        const eagles = this.scoreToPar(-2, isAverage);
        const myEagles = eagles.filter(g => g.golferId === golfer.id)[0];
        const index = eagles.findIndex(g => g.golferId === golfer.id);

        return (
            <StatLine
                header={ isAverage ? 'Eagles / Round' : 'Eagles' }
                stat={ myEagles.statistic }
                position={ index + 1 }
                count={ eagles.length }
            />
        );

    }

    renderBirdies = isAverage => {

        const { golfer } = this.props;
        const birdies = this.scoreToPar(-1, isAverage);
        const myBirdies = birdies.filter(g => g.golferId === golfer.id)[0];
        const index = birdies.findIndex(g => g.golferId === golfer.id);

        return (
            <StatLine
                header={ isAverage ? 'Birdies / Round' : 'Birdies' }
                stat={ myBirdies.statistic }
                position={ index + 1 }
                count={ birdies.length }
            />
        );

    }

    renderPars = isAverage => {

        const { golfer } = this.props;
        const pars = this.scoreToPar(0, isAverage);
        const myPars = pars.filter(g => g.golferId === golfer.id)[0];
        const index = pars.findIndex(g => g.golferId === golfer.id);

        return (
            <StatLine
                header={ isAverage ? 'Pars / Round' : 'Pars' }
                stat={ myPars.statistic }
                position={ index + 1 }
                count={ pars.length }
            />
        );

    }

    hasRounds = () => {

        const { rounds, golfer } = this.props;
        const count = getRoundsForGolfer(rounds, golfer.id, true, '-1', true).length;

        return (count > 0);

    }

    render() {

        if (!this.hasRounds()) return <Notification>No rounds.</Notification>;

        return (
            <div className="StatsList">
                { this.renderScoringAverage() }
                { this.renderLowGross() }
                { this.renderEagles(false) }
                { this.renderEagles(true) }
                { this.renderBirdies(false) }
                { this.renderBirdies(true) }
                { this.renderPars(false) }
                { this.renderPars(true) }
            </div>
        );

    }

}

export default StatsList;
