// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './SearchBar.scss';

const SearchBar = props => (
    <div className="SearchBar">
        <input type="text" value={ props.value } onChange={ props.onChange } placeholder="Search" />
        <div className="icon">
            <img src="/images/icons/search.svg" alt="Search" />
        </div>
    </div>
);

SearchBar.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
};

SearchBar.defaultProps = {
    onChange: f => f,
    value: '',
};

export default SearchBar;
