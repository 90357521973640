// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';

// Components.
import Input from '../../components/Input';
import Tab from '../../components/Tab';
import Notification from '../../components/Notification';
import ActionButton from '../../components/ActionButton';

// Styles.
import './Course.scss';

// Utils.
import { getCoursePar, getCourseYardage, getRoundsForCourse, getLowScoresForCourse } from '../../utils/golf';

class Course extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            courses: PropTypes.object,
            rounds: PropTypes.object,
            golfers: PropTypes.object,
            setLoading: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
            setModalContent: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            courses: null,
            rounds: null,
            golfers: null,
        }
    }

    constructor(props) {

        super(props);

        this.state = {
            teeColorSelect: props.courses ? Object.keys(this.course(props.match.params.course_uid).tees)[0] : null,
            courseTab: 'left',
        };

    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Course Profile');
    }

    course = uid => {
        return this.props.courses ? this.props.courses[uid] : null;
    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value });
    }

    getRoundsPlayed = () => {

        const { rounds, match } = this.props;
        const courseRounds = getRoundsForCourse(rounds, match.params.course_uid);

        return courseRounds.length;

    }

    getLowGross = (numberOnly = false) => {

        const { rounds, golfers, match } = this.props;
        const lowGross = getLowScoresForCourse(rounds, golfers, match.params.course_uid);

        if (numberOnly) {
            return lowGross ? lowGross.gross : '-';
        }

        return lowGross ? `${ lowGross.gross } (${ lowGross.name })` : '-';

    }

    getLowNet = () => {

        const { rounds, golfers, match } = this.props;
        const lowNet = getLowScoresForCourse(rounds, golfers, match.params.course_uid, false);

        return lowNet ? `${ lowNet.scoreToPar } (${ lowNet.name })` : '-';

    }

    infoSelected = () => {
        this.setState({ courseTab: 'left' });
    }

    historySelected = () => {
        this.setState({ courseTab: 'right' });
    }

    toggleInfo = () => {
        this.props.setModalContent(
            <>
                <p>Press the “Tee Color” row to change the selected tee color.</p>
            </>
        );
    }

    render() {

        const { teeColorSelect, courseTab } = this.state;
        const { user, match, golfers } = this.props;
        const course = this.course(match.params.course_uid);

        if (!user || !course || !golfers) return <Redirect to="/courses" />;

        return (
            <section className="Course">
                <div className="hero">
                    <div className="name">
                        <h2>{ course.name }</h2>
                        <h3>{ course.location }</h3>
                    </div>
                    <div className="icon">
                        <span>{ course.name.substring(0, 1) }</span>
                    </div>
                    <div className="par">
                        <div>
                            <h4>{ getCoursePar(course, teeColorSelect) }</h4>
                            <h3>Par</h3>
                        </div>
                        <div>
                            <h4>{ this.getLowGross(true) }</h4>
                            <h3>Course Record</h3>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <Tab
                        titles={{
                            left: 'Info',
                            right: 'History',
                        }}
                        toggleLeft={ this.infoSelected }
                        toggleRight={ this.historySelected }
                        selected={ courseTab }
                    />
                    {
                        courseTab === 'left' &&
                        <>
                            <Input
                                type="select"
                                value={ teeColorSelect }
                                options={ Object.keys(course.tees).map(tee => ({ label: tee, value: tee })) }
                                header="Tee Color"
                                onChange={ e => this.onChange(e, 'teeColorSelect') }
                            />
                            <Notification value={ getCourseYardage(course, teeColorSelect) }>Yardage</Notification>
                            <Notification value={ course.tees[teeColorSelect].slope }>Slope</Notification>
                            <Notification value={ course.tees[teeColorSelect].rating }>Rating</Notification>
                            <ActionButton icon="info" onClick={ this.toggleInfo } />
                        </>
                    }
                    {
                        courseTab === 'right' &&
                        <>
                            <Notification value={ this.getRoundsPlayed() }>Rounds Played</Notification>
                            <Notification value={ this.getLowGross() }>Course Record</Notification>
                            <Notification value={ this.getLowNet() }>Low Net</Notification>
                        </>
                    }
                    <Link to={ `/courses/${ match.params.course_uid }/edit` }>
                        <ActionButton icon="edit" />
                    </Link>
                </div>
            </section>
        );

    }

}

export default Course;
