// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components.
import LeaderboardHeader from '../LeaderboardHeader';
import LeaderboardRow from '../LeaderboardRow';
import ActionButton from '../ActionButton';
import MatchPreview from '../MatchPreview';

// Styles.
import './Leaderboard.scss';

// Utils.
import { getSum, getNetScoreForHoles, getNetScoreForHole, getStandingsForLeaders } from '../../utils/golf';

class Leaderboard extends Component {

    static get propTypes() {
        return {
            golfers: PropTypes.object,
            round: PropTypes.object,
            roundId: PropTypes.string,
            rounds: PropTypes.object,
            linkOut: PropTypes.bool,
            setModalContent: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            golfers: null,
            round: null,
            roundId: null,
            rounds: null,
            linkOut: false,
        }
    }

    sortYellowball = () => {

        const { golfers, round, roundId } = this.props;
        const leaderboardLeaders = [];
        const holes = round.course.details.tees[round.teeColor].holes;
        const leaders = [];

        for (let teeTime of Object.keys(round.teeTimes)) {

            // Grab the team captain and use their
            // info as reference for the team.
            const team = Object.entries(round.teeTimes[teeTime].golfers).filter(golfer => golfer[1].order === 1)[0];
            const name = golfers[team[0]].name;
            const breakpoint = (name.lastIndexOf(' ') > -1) ? name.lastIndexOf(' ') : 0;
            const teamName = `Team ${ name.substring(breakpoint + 1) }`;

            let scores = undefined;
            let gross = 0;
            let netScore = {};

            const groupSize = Object.values(round.teeTimes[teeTime].golfers).length;

            for (let i = 1; i <= 18; i++) {

                const order = ((i - 1) % groupSize) + 1;
                let score = undefined;
                let golfer = Object.values(round.teeTimes[teeTime].golfers).filter(golfer => golfer.order === order)[0];

                if (golfer.scores && golfer.scores[i]) {
                    score = golfer.scores[i];
                }

                if (score) {
                    if (!scores) scores = [];
                    scores[i] = getNetScoreForHole(score, holes[i], golfer.handicap);
                }

            }

            if (scores) {
                gross = Object.values(scores).reduce(getSum);
                netScore = getNetScoreForHoles(scores, holes, 0);
            }

            leaders.push({
                id: team[0],
                name: teamName,
                icon: null,
                net: scores ? netScore.net : 0,
                scoreToPar: scores ? netScore.scoreToPar : 'E',
                gross: scores ? gross : 0,
                holesPlayed: scores ? Object.keys(scores).length : 0,
                teeTimeId: teeTime,
                roundId: roundId,
                holes,
                scores,
            });

        }

        leaderboardLeaders.push({
            [roundId]: leaders
        });

        return getStandingsForLeaders(leaderboardLeaders, roundId);

    }

    sortBestball = () => {

        const { golfers, round, roundId } = this.props;
        const leaderboardLeaders = [];
        const holes = round.course.details.tees[round.teeColor].holes;
        const leaders = [];

        for (let teeTime of Object.keys(round.teeTimes)) {

            // Grab the team captain and use their
            // info as reference for the team.
            const team = Object.entries(round.teeTimes[teeTime].golfers).filter(golfer => golfer[1].order === 1)[0];
            const name = golfers[team[0]].name;
            const breakpoint = (name.lastIndexOf(' ') > -1) ? name.lastIndexOf(' ') : 0;
            const teamName = `Team ${ name.substring(breakpoint + 1) }`;

            let scores = undefined;
            let gross = 0;
            let netScore = {};

            for (let i = 1; i <= 18; i++) {

                let score = undefined;

                for (let golfer of Object.values(round.teeTimes[teeTime].golfers)) {

                    let net = null;

                    if (golfer.scores && golfer.scores[i]) {

                        net = getNetScoreForHole(golfer.scores[i], holes[i], golfer.handicap);
                        score = !score ? net : Math.min(score, net);

                    }

                }

                if (score) {
                    if (!scores) scores = [];
                    scores[i] = score;
                }

            }

            if (scores) {
                gross = Object.values(scores).reduce(getSum);
                netScore = getNetScoreForHoles(scores, holes, 0);
            }

            leaders.push({
                id: team[0],
                name: teamName,
                icon: null,
                net: scores ? netScore.net : 0,
                scoreToPar: scores ? netScore.scoreToPar : 'E',
                gross: scores ? gross : 0,
                holesPlayed: scores ? Object.keys(scores).length : 0,
                teeTimeId: teeTime,
                roundId: roundId,
                holes,
                scores,
            });

        }

        leaderboardLeaders.push({
            [roundId]: leaders
        });

        return getStandingsForLeaders(leaderboardLeaders, roundId);

    }

    sortLeaders = () => {

        const { golfers, round, roundId, rounds } = this.props;
        const leaderboardLeaders = [];

        if (round.format === 'scramble') {

            const holes = round.course.details.tees[round.teeColor].holes;
            const leaders = [];

            for (let teeTime of Object.keys(round.teeTimes)) {

                // Grab the team captain and use their
                // info as reference for the team.
                const team = Object.entries(round.teeTimes[teeTime].golfers).filter(golfer => golfer[1].order === 1)[0];
                const name = golfers[team[0]].name;
                const breakpoint = (name.lastIndexOf(' ') > -1) ? name.lastIndexOf(' ') : 0;
                const teamName = `Team ${ name.substring(breakpoint + 1) }`;

                const scores = team[1].scores;
                let gross = 0;
                let netScore = {};

                if (scores) {
                    gross = Object.values(scores).reduce(getSum);
                    netScore = getNetScoreForHoles(scores, holes, 0);
                }

                leaders.push({
                    id: team[0],
                    name: teamName,
                    icon: null,
                    net: scores ? netScore.net : 0,
                    scoreToPar: scores ? netScore.scoreToPar : 'E',
                    gross: scores ? gross : 0,
                    holesPlayed: scores ? Object.keys(scores).length : 0,
                    teeTimeId: teeTime,
                    roundId: roundId,
                    holes,
                    scores,
                });

            }

            leaderboardLeaders.push({
                [roundId]: leaders
            });

        } else {

            const tournament = round.tournament;
            let leaderboardRounds = [{ ...round, id: roundId }];

            if (tournament) {

                const tournamentRounds = Object.entries(rounds).filter(r => r[1].tournament === tournament);

                leaderboardRounds = tournamentRounds.map(tournamentRound => ({
                    ...tournamentRound[1],
                    id: tournamentRound[0],
                }));

            }

            for (let leaderboardRound of leaderboardRounds) {

                if (!leaderboardRound.teeTimes) continue;

                const holes = leaderboardRound.course.details.tees[leaderboardRound.teeColor].holes;
                const leaders = [];

                for (let teeTime of Object.keys(leaderboardRound.teeTimes)) {
                    for (let golfer of Object.entries(leaderboardRound.teeTimes[teeTime].golfers)) {

                        const scores = golfer[1].scores;
                        let gross = 0;
                        let netScore = {};

                        if (scores) {
                            gross = Object.values(scores).reduce(getSum);
                            netScore = getNetScoreForHoles(scores, holes, golfer[1].handicap);
                        }

                        leaders.push({
                            id: golfer[0],
                            name: golfers[golfer[0]].name,
                            nickname: golfers[golfer[0]].nickname,
                            icon: golfers[golfer[0]].icon,
                            net: scores ? netScore.net : 0,
                            scoreToPar: scores ? netScore.scoreToPar : 'E',
                            gross: scores ? gross : 0,
                            holesPlayed: scores ? Object.keys(scores).length : 0,
                            teeTimeId: teeTime,
                            roundId: roundId,
                            holes,
                            scores,
                        });

                    }
                }

                leaderboardLeaders.push({
                    [leaderboardRound.id]: leaders
                });

            }

        }

        return getStandingsForLeaders(leaderboardLeaders, roundId);

    }

    matches = () => {

        const { round } = this.props;

        if (!round.matches) return null;

        const matches = Object.values(round.matches).sort((a, b) => a.date - b.date).map((match, key) => (
            <MatchPreview match={ match } golfers={ this.props.golfers } round={ round } isLeaderboard key={ key } />
        ));

        return matches;

    }

    toggleInfo = () => {
        this.props.setModalContent(
            <>
                <p>Press on a player’s row to expand it and view their complete scorecard.</p>
                <p>Press the row again to collapse it.</p>
            </>
        );
    }

    render() {

        const { round, linkOut } = this.props;

        return (
            <div className="Leaderboard">
                <LeaderboardHeader isTournament={ round.tournament !== '' } format={ round.format } />
                {
                    this.sortLeaders().map((leader, key) => (
                        <LeaderboardRow leader={ leader } isTournament={ round.tournament !== '' } linkOut={ linkOut } key={ key } />
                    ))
                }
                {
                    (round.format === 'yellowball') &&
                    <div className="AltHeader">
                        <h4>Yellowball Standings</h4>
                    </div>
                }
                {
                    (round.format === 'yellowball') &&
                    this.sortYellowball().map((leader, key) => (
                        <LeaderboardRow leader={ leader } isTournament={ false } linkOut={ linkOut } key={ key } />
                    ))
                }
                {
                    (round.format === 'bestball') &&
                    <div className="AltHeader">
                        <h4>Best Ball Standings</h4>
                    </div>
                }
                {
                    (round.format === 'bestball') &&
                    this.sortBestball().map((leader, key) => (
                        <LeaderboardRow leader={ leader } isTournament={ false } linkOut={ linkOut } key={ key } />
                    ))
                }
                {
                    round.matches &&
                    <div className="AltHeader">
                        <h4>Matches</h4>
                    </div>
                }
                { this.matches() }
                <ActionButton icon="info" onClick={ this.toggleInfo } />
            </div>
        );

    }

}

export default Leaderboard;
