// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './SummaryRow.scss';

// Components.
import GolferRow from '../GolferRow';

class SummaryRow extends React.Component {

    static get propTypes() {
        return {
            golfer: PropTypes.object.isRequired,
        }
    }

    state = {
        isExpanded: false,
    }

    toggle = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    render() {

        const { golfer } = this.props;
        const { isExpanded } = this.state;

        return (
            <div className="SummaryRow">
                <div onClick={ this.toggle }>
                    <GolferRow
                        primary={ golfer.name }
                        secondary={ golfer.nickname }
                        tertiary={ golfer.gross }
                        icon={ golfer.icon }
                    />
                </div>
                {
                    isExpanded &&
                    <>
                        <div className="totals">
                            <div>
                                <h4>Eagles</h4>
                                <h5>{ golfer.summary.eagles }</h5>
                            </div>
                            <div>
                                <h4>Birdies</h4>
                                <h5>{ golfer.summary.birdies }</h5>
                            </div>
                            <div>
                                <h4>Pars</h4>
                                <h5>{ golfer.summary.pars }</h5>
                            </div>
                            <div>
                                <h4>Bogeys</h4>
                                <h5>{ golfer.summary.bogeys }</h5>
                            </div>
                            <div>
                                <h4>Doubles</h4>
                                <h5>{ golfer.summary.doubles }</h5>
                            </div>
                            <div>
                                <h4>Triples+</h4>
                                <h5>{ golfer.summary.triples }</h5>
                            </div>
                        </div>
                    </>
                }
            </div>
        );

    }

}

export default SummaryRow;
