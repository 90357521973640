// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import Input from '../Input';

// Styles.
import './HoleRow.scss';

const HoleRow = ({ hole, onHoleChange, par, yardage, strokeIndex }) => (
    <div className="HoleRow">
        <h3>{ hole }</h3>
        <div>
            <Input
                type="select"
                options={[
                    { label: '-', value: 0 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                ]}
                onChange={ e => onHoleChange(hole, e.target.value, yardage, strokeIndex) }
                value={ par }
            />
        </div>
        <div>
            <Input
                type="number"
                onChange={ e => onHoleChange(hole, par, e.target.value, strokeIndex) }
                value={ yardage }
            />
        </div>
        <div>
            <Input
                type="select"
                options={[
                    { label: '-', value: 0 },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                    { label: '6', value: 6 },
                    { label: '7', value: 7 },
                    { label: '8', value: 8 },
                    { label: '9', value: 9 },
                    { label: '10', value: 10 },
                    { label: '11', value: 11 },
                    { label: '12', value: 12 },
                    { label: '13', value: 13 },
                    { label: '14', value: 14 },
                    { label: '15', value: 15 },
                    { label: '16', value: 16 },
                    { label: '17', value: 17 },
                    { label: '18', value: 18 },
                ]}
                onChange={ e => onHoleChange(hole, par, yardage, e.target.value) }
                value={ strokeIndex }
            />
        </div>
    </div>
);

HoleRow.propTypes = {
    hole: PropTypes.number.isRequired,
    onHoleChange: PropTypes.func.isRequired,
};

export default HoleRow;
