import EditCourse from './EditCourse';
import { connect } from 'react-redux';
import { setLoading, setPageTitle, setModalContent } from '../../redux/actions/app';

const mapStateToProps = state => ({
    user: state.app.user,
    courses: state.app.courses,
});

const mapDispatchToProps = {
    setLoading,
    setPageTitle,
    setModalContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCourse);
