// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './FileInput.scss';

const FileInput = props => (
    <div className="FileInput">
        <h4>Icon</h4>
        <label htmlFor="file">
            <input id="file" type="file" onChange={ props.onFileChange } />
            <span>{ props.filename || 'No File Chosen' }</span>
        </label>
    </div>
);

FileInput.propTypes = {
    onFileChange: PropTypes.func.isRequired,
    filename: PropTypes.string,
};

FileInput.defaultProps = {
    filename: null,
};

export default FileInput;
