// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Sections.
import Home from '../Home';
import Play from '../Play';
import CreateRound from '../CreateRound';
import TournamentRoundList from '../TournamentRoundList';
import CreateTeeTime from '../CreateTeeTime';
import TeeTimes from '../TeeTimes';
import TeeTime from '../TeeTime';
import Golfers from '../Golfers';
import CreateGolfer from '../CreateGolfer';
import EditGolfer from '../EditGolfer';
import Golfer from '../Golfer';
import Courses from '../Courses';
import Course from '../Course';
import CreateCourse from '../CreateCourse';
import CreateTeeColor from '../CreateTeeColor';
import EditCourse from '../EditCourse';
import Tournaments from '../Tournaments';
import CreateTournament from '../CreateTournament';
import Tournament from '../Tournament';
import Rounds from '../Rounds';
import Round from '../Round';
import Stats from '../Stats';

// Components.
import NavBar from '../../components/NavBar';
import Menu from '../../components/Menu';
import Loader from '../../components/Loader';
import ConnectionWarning from '../../components/ConnectionWarning';
import Modal from '../../components/Modal';

// Styles.
import './App.scss';

// Firebase.
import { auth } from '../../firebase/config';
import { createUser, getUser, getSettings, getGolfers, getCourses, getTournaments, getRounds, getConnectionStatus } from '../../firebase/db';
import { isLoggedIn } from '../../firebase/helpers';

class App extends Component {

    static get propTypes() {
        return {
            setConnectionStatus: PropTypes.func.isRequired,
            setLoading: PropTypes.func.isRequired,
            setUser: PropTypes.func.isRequired,
            setSettings: PropTypes.func.isRequired,
            setGolfers: PropTypes.func.isRequired,
            setCourses: PropTypes.func.isRequired,
            setTournaments: PropTypes.func.isRequired,
            setRounds: PropTypes.func.isRequired,
            isConnected: PropTypes.bool,
            isLoading: PropTypes.bool,
            user: PropTypes.object,
            pageTitle: PropTypes.string,
            prevPageTitle: PropTypes.string,
            modalContent: PropTypes.node,
        }
    }

    static get defaultProps() {
        return {
            isConnected: false,
            isLoading: true,
            user: null,
            pageTitle: null,
            prevPageTitle: null,
            modalContent: null,
        }
    }

    state = {
        isMenuOpen: false,
    }

    componentDidMount() {

        getConnectionStatus().on('value', snapshot => {
            this.props.setConnectionStatus(snapshot.val());
        });

        auth.onAuthStateChanged(user => {

            if (user) {
                getUser(user).then(snapshot => {

                    const val = snapshot.val();

                    if (!val) createUser(user).then(() => this.listen(user));
                    else this.listen(user);

                });
            } else {
                this.props.setGolfers(null);
                this.props.setSettings(null);
                this.props.setLoading(false);
            }

            this.props.setUser(user);

        });

    }

    listen(user) {

        Promise.all([
            getSettings(user).on('value', snapshot => {
                this.props.setSettings(snapshot.val());
            }),
            getGolfers(user).on('value', snapshot => {

                const golfers = {};

                snapshot.forEach(child => {
                    golfers[child.key] = child.val();
                });

                this.props.setGolfers(golfers);

            }),
            getCourses(user).on('value', snapshot => {

                const courses = {};

                snapshot.forEach(child => {
                    courses[child.key] = child.val();
                });

                this.props.setCourses(courses);

            }),
            getTournaments(user).on('value', snapshot => {

                const tournaments = {};

                snapshot.forEach(child => {
                    tournaments[child.key] = child.val();
                });

                this.props.setTournaments(tournaments);

            }),
            getRounds(user).on('value', snapshot => {

                const rounds = {};

                snapshot.forEach(child => {
                    rounds[child.key] = child.val();
                });

                this.props.setRounds(rounds);

            }),
        ]).then(() => {
            this.props.setLoading(false);
        });

    }

    toggleMenu = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen });
    }

    render() {

        const { isMenuOpen } = this.state;
        const { isConnected, isLoading, user, pageTitle, modalContent } = this.props;

        return (
            <div className="App">
                <Router>
                    <div id="routeWrapper">
                        { isLoggedIn(user) && <NavBar isMenuOpen={ isMenuOpen } toggleMenu={ this.toggleMenu } pageTitle={ pageTitle } /> }
                        { isMenuOpen && <Menu toggle={ this.toggleMenu } /> }
                        <Switch>
                            <Route exact path="/" component={ Home } />
                            <Route exact path="/play" component={ Play } />
                            <Route exact path="/play/create" component={ CreateRound } />
                            <Route exact path="/play/tournaments/:tournament_uid" component={ TournamentRoundList } />
                            <Route exact path="/play/rounds/:round_uid" component={ TeeTimes } />
                            <Route exact path="/play/rounds/:round_uid/create" component={ CreateTeeTime } />
                            <Route exact path="/play/rounds/:round_uid/teetime/:teetime_uid" component={ TeeTime } />
                            <Route exact path="/golfers" component={ Golfers } />
                            <Route exact path="/golfers/create" component={ CreateGolfer } />
                            <Route exact path="/golfers/:golfer_uid/edit" component={ EditGolfer } />
                            <Route path="/golfers/:golfer_uid" component={ Golfer } />
                            <Route exact path="/courses" component={ Courses } />
                            <Route path="/courses/create" component={ CreateCourse } />
                            <Route path="/courses/:course_uid/edit" component={ EditCourse } />
                            <Route path="/courses/:course_uid/create" component={ CreateTeeColor } />
                            <Route path="/courses/:course_uid" component={ Course } />
                            <Route exact path="/tournaments" component={ Tournaments } />
                            <Route exact path="/tournaments/create" component={ CreateTournament } />
                            <Route path="/tournaments/:tournament_uid" component={ Tournament } />
                            <Route exact path="/rounds" component={ Rounds } />
                            <Route exact path="/rounds/:round_uid" component={ Round } />
                            <Route exact path="/stats" component={ Stats } />
                        </Switch>
                        { isLoading && <Loader /> }
                        <Modal content={ modalContent } />
                        { !isConnected && <ConnectionWarning /> }
                    </div>
                </Router>
            </div>
        );

    }

}

export default App;
