// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles.
import './TeeTimePreview.scss';

// Components.
import Notification from '../Notification';
import GolferRow from '../GolferRow';
import Button from '../Button';

class TeeTimePreview extends React.Component {

    static get propTypes() {
        return {
            teeTime: PropTypes.array.isRequired,
            golfers: PropTypes.object.isRequired,
            round: PropTypes.string.isRequired,
        }
    }

    state = {
        isExpanded: false,
    }

    toggle = () => {
        if (!this.props.teeTime[1].complete) {
            this.setState({ isExpanded: !this.state.isExpanded });
        }
    }

    render() {

        const { teeTime, golfers, round } = this.props;
        const complete = teeTime[1].complete;

        return (
            <div className={ `TeeTimePreview ${ complete && 'complete' }` }>
                <div onClick={ this.toggle }>
                    <Notification complete={ teeTime[1].complete ? true : false }>{ teeTime[1].startTime || '12:00' }</Notification>
                </div>
                {
                    this.state.isExpanded &&
                    <div className={ `golfers c${ Object.keys(teeTime[1].golfers).length }` }>
                        {
                            Object.entries(teeTime[1].golfers).sort((golferA, golferB) => golferA[1].order - golferB[1].order).map((golfer, key) => (
                                <GolferRow
                                    primary={ golfers[golfer[0]].name }
                                    secondary={ golfers[golfer[0]].nickname }
                                    icon={ golfers[golfer[0]].icon }
                                    key={ key }
                                />
                            ))
                        }
                    </div>
                }
                {
                    this.state.isExpanded &&
                    <div className="button">
                        <Link to={ `/play/rounds/${ round }/teetime/${ teeTime[0] }` }>
                            <Button>Play</Button>
                        </Link>
                    </div>
                }
            </div>
        );

    }

}

export default TeeTimePreview;
