// Modules.
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components.
import ScorecardSticky from '../ScorecardSticky';
import ScorecardHole from '../ScorecardHole';
import Button from '../Button';

// Styles.
import './Scorecard.scss';

// Firebase.
import { setScore, signScorecard } from '../../firebase/db';

class Scorecard extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            teeTime: PropTypes.object,
            golfers: PropTypes.object,
            round: PropTypes.object,
            roundId: PropTypes.string,
            teeTimeId: PropTypes.string,
            complete: PropTypes.bool,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            teeTime: null,
            golfers: null,
            round: null,
            roundId: null,
            teeTimeId: null,
            complete: false,
        }
    }

    state = {
        selectedGolferId: null,
        selectedHole: null,
    }

    frontNine = () => {
        return this.renderHoles(1, 9);
    }

    backNine = () => {
        return this.renderHoles(10, 18);
    }

    renderHoles = (start, stop) => {

        const { round, teeTime, settings } = this.props;
        const teeColor = round.teeColor;
        const holes = round.course.details.tees[teeColor].holes;

        const scorecardHoles = [];

        for (let i = start; i <= stop; i++) {
            scorecardHoles.push(
                <ScorecardHole
                    holes={ holes }
                    holeNumber={ i }
                    teeTime={ teeTime }
                    start={ i }
                    stop={ i }
                    key={ i }
                    openScoreSelector={ this.openScoreSelector }
                    settings={ settings }
                    isScramble={ round.format === 'scramble' }
                />
            );
        }

        return scorecardHoles;

    }

    out = () => {
        return this.renderHoleTotal(1, 9, 'OUT');
    }

    in = () => {
        return this.renderHoleTotal(10, 18, 'IN');
    }

    total = () => {
        return this.renderHoleTotal(1, 18, 'TOTAL');
    }

    renderHoleTotal = (start, stop, label, hideDetails) => {

        const { round, teeTime, settings } = this.props;
        const teeColor = round.teeColor;
        const holes = round.course.details.tees[teeColor].holes;

        return (
            <ScorecardHole
                holes={ holes }
                holeNumber={ label }
                total
                teeTime={ teeTime }
                start={ start }
                stop={ stop }
                settings={ settings }
                hideDetails={ hideDetails }
                isScramble={ round.format === 'scramble' }
            />
        );

    }

    openScoreSelector = (selectedGolferId, selectedHole) => {

        if (this.props.complete) return;

        this.setState({
            selectedGolferId,
            selectedHole,
        });

    }

    setScore = score => {

        const { round, user, roundId, teeTimeId } = this.props;
        const { selectedGolferId, selectedHole } = this.state;

        if (round.format === 'scramble') {

            // Give every golfer in the tee time the same score.
            for (let golferId of Object.keys(this.props.teeTime.golfers)) {
                setScore(
                    user,
                    roundId,
                    teeTimeId,
                    golferId,
                    selectedHole,
                    score
                ).then(this.closeScoreSelector);
            }

        } else {

            setScore(
                user,
                roundId,
                teeTimeId,
                selectedGolferId,
                selectedHole,
                score
            ).then(this.closeScoreSelector);

        }

    }

    teeTimeComplete = () => {

        let numGolfers = 0;
        let scoresEntered = 0;

        for (let golfer of Object.values(this.props.teeTime.golfers)) {
            numGolfers++;
            scoresEntered += golfer.scores ? Object.values(golfer.scores).length : 0;
        }

        return (scoresEntered === (numGolfers * 18));

    }

    signScorecard = () => {

        const { user, roundId, teeTimeId } = this.props;

        signScorecard(user, roundId, teeTimeId).then(() => {
            this.props.history.push(`/play/rounds/${ roundId }`);
        });

    }

    closeScoreSelector = () => {
        this.setState({
            selectedGolferId: null,
            selectedHole: null,
        });
    }

    render() {

        const { teeTime, golfers, complete } = this.props;
        const { selectedGolferId, selectedHole } = this.state;

        return (
            <div className={ `Scorecard ${ complete && 'complete' }` }>
                <ScorecardSticky teeTime={ teeTime } golfers={ golfers } />
                <div className={ `scoreSelector ${ selectedGolferId && selectedHole && 'active' }` }>
                    <div className="close" onClick={ this.closeScoreSelector } />
                    <div className="panel">
                        <h5>Enter <strong>Hole { selectedHole }</strong> score for <strong>{ golfers[selectedGolferId] && golfers[selectedGolferId].name }</strong></h5>
                        <button className="s" onClick={ () => this.setScore(1) }>1</button>
                        <button className="s" onClick={ () => this.setScore(2) }>2</button>
                        <button className="s" onClick={ () => this.setScore(3) }>3</button>
                        <button className="s" onClick={ () => this.setScore(4) }>4</button>
                        <button className="s" onClick={ () => this.setScore(5) }>5</button>
                        <button className="s" onClick={ () => this.setScore(6) }>6</button>
                        <button className="s" onClick={ () => this.setScore(7) }>7</button>
                        <button className="s" onClick={ () => this.setScore(8) }>8</button>
                        <button className="s" onClick={ () => this.setScore(9) }>9</button>
                        <button className="s" onClick={ () => this.setScore(10) }>10</button>
                        <button className="s" onClick={ () => this.setScore(11) }>11</button>
                        <button className="s" onClick={ () => this.setScore(12) }>12</button>
                        <button className="s" onClick={ () => this.setScore(13) }>13</button>
                        <button className="s" onClick={ () => this.setScore(14) }>14</button>
                        <button className="s" onClick={ () => this.setScore(15) }>15</button>
                        <button className="s" onClick={ () => this.setScore(16) }>16</button>
                        <div className="clear">
                            <Button onClick={ () => this.setScore(null) }>Clear Score</Button>
                        </div>
                    </div>
                </div>
                { this.frontNine() }
                { this.out() }
                { this.backNine() }
                { this.in() }
                { this.total() }
                { !complete && <Button disabled={ !this.teeTimeComplete() } onClick={ this.signScorecard }>Sign Scorecard</Button> }
            </div>
        );

    }

}

export default withRouter(Scorecard);
