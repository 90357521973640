// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Components.
import LeaderboardHeader from '../../components/LeaderboardHeader';
import LeaderboardRow from '../../components/LeaderboardRow';
import ActionButton from '../../components/ActionButton';

// Styles.
import './Tournament.scss';

// Utils.
import { getSum, getNetScoreForHoles, getStandingsForTournament } from '../../utils/golf';

class Tournament extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            tournaments: PropTypes.object,
            golfers: PropTypes.object,
            rounds: PropTypes.object,
            setPageTitle: PropTypes.func.isRequired,
            setModalContent: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            tournaments: null,
            golfers: null,
            rounds: null,
        }
    }

    componentDidMount() {

        document.getElementById('routeWrapper').scrollTo(0, 0);

        const tournament = this.tournament(this.props.match.params.tournament_uid);
        this.props.setPageTitle(tournament ? tournament.name : '');

    }

    tournament = uid => (
        this.props.tournaments ? this.props.tournaments[uid] : null
    )

    results = () => {

        const { match, rounds, golfers } = this.props;
        const leaderboardLeaders = [];

        const tournament = match.params.tournament_uid;
        const tournamentRounds = Object.entries(rounds).filter(r => r[1].tournament === tournament);
        const leaderboardRounds = tournamentRounds.map(tournamentRound => ({
            ...tournamentRound[1],
            id: tournamentRound[0],
        }));

        for (let leaderboardRound of leaderboardRounds) {

            const holes = leaderboardRound.course.details.tees[leaderboardRound.teeColor].holes;
            const leaders = [];

            for (let teeTime of Object.keys(leaderboardRound.teeTimes)) {
                for (let golfer of Object.entries(leaderboardRound.teeTimes[teeTime].golfers)) {

                    const scores = golfer[1].scores;

                    if (scores) {

                        const gross = Object.values(scores).reduce(getSum);
                        const netScore = getNetScoreForHoles(scores, holes, golfer[1].handicap);

                        leaders.push({
                            id: golfer[0],
                            name: golfers[golfer[0]].name,
                            nickname: golfers[golfer[0]].nickname,
                            icon: golfers[golfer[0]].icon,
                            net: netScore.net,
                            scoreToPar: netScore.scoreToPar,
                            gross,
                            holesPlayed: Object.keys(scores).length,
                        });

                    }

                }
            }

            leaderboardLeaders.push({
                [leaderboardRound.id]: leaders
            });

        }

        return getStandingsForTournament(leaderboardLeaders);

    }

    numRounds = () => {

        const { match, rounds } = this.props;
        const tournamentRounds = Object.entries(rounds).filter(r => r[1].tournament === match.params.tournament_uid);

        return tournamentRounds.length;

    }

    toggleInfo = () => {
        this.props.setModalContent(
            <>
                <p>Press on a player's row to expand it and view their individual round totals.</p>
                <p>Press the row again to collapse it.</p>
            </>
        );
    }

    render() {

        const { user, golfers, rounds, match } = this.props;
        const tournament = this.tournament(match.params.tournament_uid);

        if (!user || !golfers || !rounds || !tournament.complete) return <Redirect to="/" />;

        return (
            <section className="Tournament">
                <div className="headerWrapper">
                    <LeaderboardHeader isTournamentResults count={ this.numRounds() } />
                </div>
                {
                    this.results().map((leader, key) => (
                        <LeaderboardRow
                            leader={ leader }
                            isTournamentResults
                            key={ key }
                        />
                    ))
                }
                <ActionButton icon="info" onClick={ this.toggleInfo } />
            </section>
        );

    }

}

export default Tournament;
