import App from './App';
import { connect } from 'react-redux';
import { setConnectionStatus, setLoading, setUser, setSettings, setGolfers, setCourses, setTournaments, setRounds } from '../../redux/actions/app';

const mapStateToProps = state => ({
    isConnected: state.app.isConnected,
    isLoading: state.app.isLoading,
    user: state.app.user,
    pageTitle: state.app.pageTitle,
    modalContent: state.app.modalContent,
});

const mapDispatchToProps = {
    setConnectionStatus,
    setLoading,
    setUser,
    setSettings,
    setGolfers,
    setCourses,
    setTournaments,
    setRounds,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
