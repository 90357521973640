import CreateRound from './CreateRound';
import { connect } from 'react-redux';
import { setPageTitle, setLoading, setModalContent } from '../../redux/actions/app';

const mapStateToProps = state => ({
    user: state.app.user,
    courses: state.app.courses,
    tournaments: state.app.tournaments,
});

const mapDispatchToProps = {
    setPageTitle,
    setLoading,
    setModalContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRound);
