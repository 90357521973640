// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import uuid from 'uuid/v4';

// Components.
import Notification from '../../components/Notification';
import Accordion from '../../components/Accordion';
import Input from '../../components/Input';
import Button from '../../components/Button';
import ActionButton from '../../components/ActionButton';
import TeeTimePreview from '../../components/TeeTimePreview';
import MatchPreview from '../../components/MatchPreview';

// Styles.
import './TeeTimes.scss';

// Firebase.
import { completeRound, createMatch } from '../../firebase/db';

class TeeTimes extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            rounds: PropTypes.object,
            golfers: PropTypes.object,
            settings: PropTypes.object,
            setLoading: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
            setModalContent: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            rounds: null,
            golfers: null,
            settings: null,
        }
    }

    state = {
        isAddingMatch: false,
        selectedMatchType: '',
        selectedMatchHoles: '',
        golfer1: '',
        golfer2: '',
        golfer3: '',
        golfer4: '',
        golfer1Strokes: '0',
        golfer2Strokes: '0',
        golfer3Strokes: '0',
        golfer4Strokes: '0',
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Tee Times');
    }

    round = uid => {
        return this.props.rounds ? this.props.rounds[uid] : null;
    }

    roundComplete = () => {

        const round = this.round(this.props.match.params.round_uid);

        if (round.teeTimes) {
            let completedTeeTimes = Object.values(round.teeTimes).filter(teeTime => teeTime.complete);
            if (completedTeeTimes.length === Object.values(round.teeTimes).length) return true;
        }

        return false;

    }

    completeRound = () => {
        completeRound(this.props.user, this.props.match.params.round_uid).then(() => {
            this.props.history.push('/rounds');
        });
    }

    filterGolfers = excludedGolfers => {

        const round = this.round(this.props.match.params.round_uid);

        // No tee times have been created yet so
        // just check the current inputs.
        if (!round.teeTimes) return excludedGolfers;

        const golfersAlreadyPlaying = Object.values(round.teeTimes).map(teeTime => (
            Object.keys(teeTime.golfers)
        )).flat();

        return golfersAlreadyPlaying.concat(excludedGolfers);

    }

    areGolfersRemaining = () => {
        return (this.filterGolfers([]).length < Object.keys(this.props.golfers).length);
    }

    addMatch = () => {
        if (!this.state.isAddingMatch) this.setState({ isAddingMatch: true });
    }

    saveMatch = e => {

        e.preventDefault();

        this.props.setLoading(true);

        const { selectedMatchType, selectedMatchHoles, golfer1, golfer2, golfer3, golfer4, golfer1Strokes, golfer2Strokes, golfer3Strokes, golfer4Strokes } = this.state;

        let match = {};
        match.golfers = {};

        if (selectedMatchType === 'singles') {
            match['golfers'][golfer1] = { team: 1, strokes: parseInt(golfer1Strokes) };
            match['golfers'][golfer3] = { team: 2, strokes: parseInt(golfer3Strokes) };
        }

        if (selectedMatchType === 'doubles') {
            match['golfers'][golfer1] = { team: 1, strokes: parseInt(golfer1Strokes) };
            match['golfers'][golfer2] = { team: 1, strokes: parseInt(golfer2Strokes) };
            match['golfers'][golfer3] = { team: 2, strokes: parseInt(golfer3Strokes) };
            match['golfers'][golfer4] = { team: 2, strokes: parseInt(golfer4Strokes) };
        }

        match['type'] = selectedMatchType;
        match['holes'] = selectedMatchHoles;

        createMatch(this.props.user, uuid(), this.props.match.params.round_uid, match).then(() => {
            this.props.setLoading(false);
            this.resetMatch();
        });

    }

    isMatchReady = () => {
        switch (true) {
            default:
            case (this.state.isAddingMatch && !this.state.selectedMatchType):
                return false;
            case (this.state.isAddingMatch && this.state.selectedMatchType === 'singles'):
                return (
                    this.state.golfer1 !== '' &&
                    this.state.golfer3 !== '' &&
                    this.state.selectedMatchHoles !== '' &&
                    this.state.golfer1Strokes &&
                    this.state.golfer1Strokes >= 0 &&
                    this.state.golfer1Strokes <= 54 &&
                    this.state.golfer3Strokes &&
                    this.state.golfer3Strokes >= 0 &&
                    this.state.golfer3Strokes <= 54
                );
            case (this.state.isAddingMatch && this.state.selectedMatchType === 'doubles'):
                return (
                    this.state.golfer1 !== '' &&
                    this.state.golfer2 !== '' &&
                    this.state.golfer3 !== '' &&
                    this.state.golfer4 !== '' &&
                    this.state.selectedMatchHoles !== '' &&
                    this.state.golfer1Strokes &&
                    this.state.golfer1Strokes >= 0 &&
                    this.state.golfer1Strokes <= 54 &&
                    this.state.golfer2Strokes &&
                    this.state.golfer2Strokes >= 0 &&
                    this.state.golfer2Strokes <= 54 &&
                    this.state.golfer3Strokes &&
                    this.state.golfer3Strokes >= 0 &&
                    this.state.golfer3Strokes <= 54 &&
                    this.state.golfer4Strokes &&
                    this.state.golfer4Strokes >= 0 &&
                    this.state.golfer4Strokes <= 54
                );
            case (!this.state.isAddingMatch):
                return true;
        }
    }

    resetMatch = () => {
        this.setState({
            isAddingMatch: false,
            selectedMatchType: '',
            selectedMatchHoles: '',
            golfer1: '',
            golfer2: '',
            golfer3: '',
            golfer4: '',
            golfer1Strokes: '0',
            golfer2Strokes: '0',
            golfer3Strokes: '0',
            golfer4Strokes: '0',
        });
    }

    isMatchButtonDisabled = () => {
        return (this.state.isAddingMatch && !this.isMatchReady());
    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value });
    }

    filterGolfers = excludedGolfers => {

        const round = this.round(this.props.match.params.round_uid);

        // No tee times have been created yet so
        // just check the current inputs.
        if (!round.teeTimes) return excludedGolfers;

        const golfersAlreadyPlaying = Object.values(round.teeTimes).map(teeTime => (
            Object.keys(teeTime.golfers)
        )).flat();

        return golfersAlreadyPlaying.filter(x => !excludedGolfers.includes(x));

    }

    previewMatches = () => {

        const round = this.round(this.props.match.params.round_uid);

        if (!round.matches) return null;

        const matches = Object.values(round.matches).sort((a, b) => a.date - b.date).map((match, key) => (
            <MatchPreview match={ match } golfers={ this.props.golfers } thru="W-L-T" rounds={ this.props.rounds } key={ key } />
        ));

        return matches;

    }

    toggleInfo = () => {
        this.props.setModalContent(
            <>
                <p>Press on a tee time row to expand it and view the group’s golfers.</p>
                <p>Press “Play” to start tracking scores for the group.</p>
                <p>Press the tee time row again to collapse it.</p>
            </>
        );
    }

    render() {

        const { user, rounds, golfers, settings } = this.props;
        const { selectedMatchType, selectedMatchHoles, golfer1, golfer2, golfer3, golfer4, golfer1Strokes, golfer2Strokes, golfer3Strokes, golfer4Strokes } = this.state;

        if (!user || !rounds || !golfers || !settings) return <Redirect to="/" />;

        const round = this.round(this.props.match.params.round_uid);

        // Bail if a user tries to hack the URL.
        if (round.complete) return <Redirect to="/" />;

        const matches = this.previewMatches();

        return (
            <section className="TeeTimes">
                { !round.teeTimes && <Notification>There are no tee times in progress.</Notification> }
                <>
                    {
                        round.teeTimes &&
                        Object.entries(round.teeTimes).sort((a, b) => a[1].startTime.localeCompare(b[1].startTime)).map((teeTime, key) => (
                            <TeeTimePreview
                                teeTime={ teeTime }
                                golfers={ golfers }
                                round={ this.props.match.params.round_uid }
                                key={ key }
                            />
                        ))
                    }
                </>
                {
                    round.format !== 'scramble' && round.teeTimes &&
                    <Accordion>
                        {
                            matches &&
                            <div className="matchesContainer">
                                { matches }
                            </div>
                        }
                        {
                            this.state.isAddingMatch &&
                            <div className="addMatchContainer">
                                <form onSubmit={ this.saveMatch }>
                                    <Input
                                        type="select"
                                        options={[
                                            { label: '-', value: '' },
                                            { label: 'Singles', value: 'singles' },
                                            { label: 'Doubles', value: 'doubles' },
                                        ]}
                                        header="Type"
                                        value={ selectedMatchType }
                                        onChange={ e => this.onChange(e, 'selectedMatchType') }
                                    />
                                    <Input
                                        type="select"
                                        options={[
                                            { label: '-', value: '' },
                                            { label: 'Full 18', value: 'full-18' },
                                            { label: 'Front 9', value: 'front-9' },
                                            { label: 'Back 9', value: 'back-9' },
                                            // { label: 'Full 18 - Back 9 Start', value: 'full-18-back' },
                                        ]}
                                        header="Holes"
                                        value={ selectedMatchHoles }
                                        onChange={ e => this.onChange(e, 'selectedMatchHoles') }
                                    />
                                    {
                                        selectedMatchType &&
                                        <div className="addMatchHeader">
                                            <h4>Players</h4>
                                            <h4>Strokes</h4>
                                        </div>
                                    }
                                    {
                                        (selectedMatchType === 'singles' || selectedMatchType === 'doubles') &&
                                        <div className="addMatchGolfer">
                                            <Input
                                                type="select"
                                                options={[
                                                    { label: '-', value: '' },
                                                    ...Object.entries(golfers).filter(golfer => (this.filterGolfers([golfer2, golfer3, golfer4]).includes(golfer[0]))).map((golfer) => (
                                                        { label: golfer[1].name, value: golfer[0] }
                                                    ))
                                                ]}
                                                header="Golfer 1"
                                                value={ golfer1 }
                                                onChange={ e => this.onChange(e, 'golfer1') }
                                            />
                                            <Input
                                                type="number"
                                                value={ golfer1Strokes }
                                                step="1"
                                                min="0"
                                                max="54"
                                                onChange={ e => this.onChange(e, 'golfer1Strokes') }
                                            />
                                        </div>
                                    }
                                    {
                                        (selectedMatchType === 'doubles') &&
                                        <div className="addMatchGolfer">
                                            <Input
                                                type="select"
                                                options={[
                                                    { label: '-', value: '' },
                                                    ...Object.entries(golfers).filter(golfer => (this.filterGolfers([golfer1, golfer3, golfer4]).includes(golfer[0]))).map((golfer) => (
                                                        { label: golfer[1].name, value: golfer[0] }
                                                    ))
                                                ]}
                                                header="Golfer 2"
                                                value={ golfer2 }
                                                onChange={ e => this.onChange(e, 'golfer2') }
                                            />
                                            <Input
                                                type="number"
                                                value={ golfer2Strokes }
                                                step="1"
                                                min="0"
                                                max="54"
                                                onChange={ e => this.onChange(e, 'golfer2Strokes') }
                                            />
                                        </div>
                                    }
                                    {
                                        selectedMatchType &&
                                        <div className="versus"><span>VS</span></div>
                                    }
                                    {
                                        (selectedMatchType === 'singles' || selectedMatchType === 'doubles') &&
                                        <div className="addMatchGolfer">
                                            <Input
                                                type="select"
                                                options={[
                                                    { label: '-', value: '' },
                                                    ...Object.entries(golfers).filter(golfer => (this.filterGolfers([golfer1, golfer2, golfer4]).includes(golfer[0]))).map((golfer) => (
                                                        { label: golfer[1].name, value: golfer[0] }
                                                    ))
                                                ]}
                                                header={ selectedMatchType === 'singles' ? 'Golfer 2' : 'Golfer 3' }
                                                value={ golfer3 }
                                                onChange={ e => this.onChange(e, 'golfer3') }
                                            />
                                            <Input
                                                type="number"
                                                value={ golfer3Strokes }
                                                step="1"
                                                min="0"
                                                max="54"
                                                onChange={ e => this.onChange(e, 'golfer3Strokes') }
                                            />
                                        </div>
                                    }
                                    {
                                        (selectedMatchType === 'doubles') &&
                                        <div className="addMatchGolfer">
                                            <Input
                                                type="select"
                                                options={[
                                                    { label: '-', value: '' },
                                                    ...Object.entries(golfers).filter(golfer => (this.filterGolfers([golfer1, golfer2, golfer3]).includes(golfer[0]))).map((golfer) => (
                                                        { label: golfer[1].name, value: golfer[0] }
                                                    ))
                                                ]}
                                                header="Golfer 4"
                                                value={ golfer4 }
                                                onChange={ e => this.onChange(e, 'golfer4') }
                                            />
                                            <Input
                                                type="number"
                                                value={ golfer4Strokes }
                                                step="1"
                                                min="0"
                                                max="54"
                                                onChange={ e => this.onChange(e, 'golfer4Strokes') }
                                            />
                                        </div>
                                    }
                                    <Button disabled={ !this.isMatchReady() }>Save Match</Button>
                                </form>
                            </div>
                        }
                        {
                            !this.state.isAddingMatch &&
                            <div className="addMatchButtonContainer">
                                <Button onClick={ this.addMatch }>Add Match</Button>
                            </div>
                        }
                    </Accordion>
                }
                { round.teeTimes && <Button disabled={ !this.roundComplete() } onClick={ this.completeRound }>End Round</Button> }
                {
                    this.areGolfersRemaining() &&
                    <Link to={ `/play/rounds/${ this.props.match.params.round_uid }/create` }>
                        <ActionButton icon="create" />
                    </Link>
                }
                { round.teeTimes && <ActionButton icon="info" onClick={ this.toggleInfo } /> }
            </section>
        );

    }

}

export default TeeTimes;
