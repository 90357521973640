// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Components.
import RoundList from '../../components/RoundList';
import SearchBar from '../../components/SearchBar';

// Styles.
import './Rounds.scss';

class Rounds extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            rounds: PropTypes.object,
            setPageTitle: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            rounds: null,
        }
    }

    state = {
        search: '',
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Rounds');
    }

    onSearchChanged = e => {
        this.setState({ search: e.target.value });
    }

    render() {

        const { user, rounds } = this.props;
        const { search } = this.state;

        if (!user || !rounds) return <Redirect to="/" />;

        return (
            <section className="Rounds">
                {
                    Object.keys(rounds).length > 0 &&
                    <SearchBar onChange={ this.onSearchChanged } value={ search } />
                }
                <RoundList rounds={ rounds } search={ search } />
            </section>
        );

    }

}

export default Rounds;
