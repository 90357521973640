// Modules.
import React from 'react';

// Styles.
import './CreateCourseHeader.scss';

const CreateCourseHeader = () => (
    <div className="CreateCourseHeader">
        <h4>Hole</h4>
        <h4>Par</h4>
        <h4>Yardage</h4>
        <h4>Stroke Index</h4>
    </div>
);

export default CreateCourseHeader;
