// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect, withRouter } from 'react-router-dom';
import moment from 'moment';

// Styles.
import './TournamentRoundList.scss';

// Components.
import CourseRow from '../../components/CourseRow';
import Button from '../../components/Button';
import ActionButton from '../../components/ActionButton';

// Utils.
import { getTournamentRounds } from '../../utils/golf';

// Firebase.
import { completeTournament } from '../../firebase/db';

class TournamentRoundList extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            tournaments: PropTypes.object,
            rounds: PropTypes.object,
            setPageTitle: PropTypes.func.isRequired,
            setModalContent: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            tournaments: null,
            rounds: null,
        }
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle(this.props.tournaments[this.props.match.params.tournament_uid].name);
    }

    tournamentComplete = () => {

        const { match, rounds } = this.props;
        const tournamentRounds = getTournamentRounds(match.params.tournament_uid, rounds);
        const total = tournamentRounds.length;
        let count = 0;

        if (total === 0) return false;

        for (let round of tournamentRounds) {
            count += round[1].complete ? 1 : 0;
        }

        return (count === total);

    }

    completeTournament = () => {
        completeTournament(this.props.user, this.props.match.params.tournament_uid).then(() => {
            this.props.history.push('/tournaments');
        });
    }

    toggleInfo = () => {
        this.props.setModalContent(
            <>
                <p>Completed rounds will appear in lighter grey with a checkmark beside them.</p>
                <p>Once all tournament rounds have been marked as completed, the tournament can be marked as completed.</p>
                <p>Completed tournaments can be accessed by pressing “Tournaments” from the home page.</p>
                <p>Completed rounds can be accessed by pressing “Rounds” from the home page.</p>
            </>
        );
    }

    render() {

        const { user, tournaments, rounds, match } = this.props;

        if (!user || !tournaments || !rounds) return <Redirect to="/" />;

        const tournamentRounds = getTournamentRounds(match.params.tournament_uid, rounds);

        return (
            <div className="TournamentRoundList">
                {
                    tournamentRounds.map((tournamentRound, key) => {

                        if (tournamentRound[1].complete) {
                            return (
                                <CourseRow
                                    primary={ tournamentRound[1].course.details.name }
                                    secondary={ moment.unix(tournamentRound[1].date / 1000).format('dddd, MMMM D, YYYY') }
                                    complete
                                    key={ key }
                                />
                            );
                        }

                        return (
                            <Link to={ `/play/rounds/${ tournamentRound[0] }` } key={ key }>
                                <CourseRow
                                    primary={ tournamentRound[1].course.details.name }
                                    secondary={ moment.unix(tournamentRound[1].date / 1000).format('dddd, MMMM D, YYYY') }
                                />
                            </Link>
                        );

                    })
                }
                <ActionButton icon="info" onClick={ this.toggleInfo } />
                <Button disabled={ !this.tournamentComplete() } onClick={ this.completeTournament }>End Tournament</Button>
            </div>
        );

    }

}

export default withRouter(TournamentRoundList);
