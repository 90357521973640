// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';

// Components.
import RoundList from '../../components/RoundList';
import StatsList from '../../components/StatsList';
import Notification from '../../components/Notification';
import Tab from '../../components/Tab';
import ActionButton from '../../components/ActionButton';

// Styles.
import './Golfer.scss';

// Utils.
import { getRoundsForGolfer, getHandicapForGolfer, getRoundListForGolfer, getMatchPlayRecordForGolfer } from '../../utils/golf';

class Golfer extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            golfers: PropTypes.object,
            rounds: PropTypes.object,
            setLoading: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            golfers: null,
            rounds: null,
        }
    }

    state = {
        golferTab: 'left',
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Golfer Profile');
    }

    golfer = uid => {
        return this.props.golfers ? this.props.golfers[uid] : null;
    }

    getRoundsPlayed = () => {

        const { rounds, match } = this.props;
        const golferRounds = getRoundsForGolfer(rounds, match.params.golfer_uid);

        return golferRounds.length;

    }

    calculateHandicap = () => {
        const { rounds, match } = this.props;
        return getHandicapForGolfer(rounds, match.params.golfer_uid, true);
    }

    statsSelected = () => {
        this.setState({ golferTab: 'left' });
    }

    roundsSelected = () => {
        this.setState({ golferTab: 'right' });
    }

    render() {

        const { golferTab } = this.state;
        const { user, match, rounds, golfers } = this.props;
        const golfer = this.golfer(match.params.golfer_uid);

        if (!user || !golfer || !rounds) return <Redirect to="/golfers" />;

        const calculateHandicap = this.calculateHandicap();

        return (
            <section className="Golfer">
                <div className="hero">
                    <div className="name">
                        <h2>{ golfer.name }</h2>
                        { golfer.nickname && <h3>{ golfer.nickname }</h3> }
                    </div>
                    {
                        golfer.icon ?
                        <div className="icon" style={{ backgroundImage: `url('${ golfer.icon }')` }} /> :
                        <div className="icon">
                            <span>{ golfer.name.substring(0, 1) }</span>
                        </div>
                    }
                    <div className="handicap">
                        <div>
                            <h4>{ calculateHandicap ? calculateHandicap.toFixed(1) : '-' }</h4>
                            <h3>Handicap</h3>
                        </div>
                        <div>
                            <h4>{ this.getRoundsPlayed() }</h4>
                            <h3>Rounds</h3>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <Notification value={ golfer.hometown }>Hometown</Notification>
                    <Notification value={ golfer.givenHandicap }>Given Handicap</Notification>
                    <Notification value={ getMatchPlayRecordForGolfer(rounds, match.params.golfer_uid) }>Match Play Record</Notification>
                    <Tab
                        titles={{
                            left: 'Stats',
                            right: 'Rounds',
                        }}
                        toggleLeft={ this.statsSelected }
                        toggleRight={ this.roundsSelected }
                        selected={ golferTab }
                    />
                    {
                        golferTab === 'left' &&
                        <StatsList rounds={ rounds } golfers={ golfers } golfer={{ ...golfer, id: match.params.golfer_uid }} />
                    }
                    {
                        golferTab === 'right' &&
                        <RoundList rounds={ getRoundListForGolfer(rounds, match.params.golfer_uid) } golferId={ match.params.golfer_uid } />
                    }
                </div>
                <Link to={ `/golfers/${ match.params.golfer_uid }/edit` }>
                    <ActionButton icon="edit" />
                </Link>
            </section>
        );

    }

}

export default Golfer;
