// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components.
import Tab from '../../components/Tab';
import Input from '../../components/Input';
import Button from '../../components/Button';

// Styles.
import './Home.scss';

// Firebase.
import { isLoggedIn } from '../../firebase/helpers';
import { login, register, fetchUser } from '../../firebase/auth';

class Home extends Component {

    state = {
        authTab: 'left',
        loginEmail: '',
        loginPassword: '',
        signupEmail: '',
        signupPassword: '',
        signupPasswordConfirm: '',
        loginFailed: null,
        signupFailed: null,
    };

    static get propTypes() {
        return {
            isLoading: PropTypes.bool,
            user: PropTypes.object,
            setLoading: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            isLoading: false,
            user: null,
        }
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle(null);
    }

    loginSelected = () => {
        this.setState({ authTab: 'left' });
    }

    signupSelected = () => {
        this.setState({ authTab: 'right' });
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    isLoginReady  = () => (this.state.loginEmail !== '' && this.state.loginPassword !== '');
    isSignupReady = () => (this.state.signupEmail !== '' && this.state.signupPassword !== '' && this.state.signupPasswordConfirm !== '');

    login = e => {

        e.preventDefault();

        this.setState({ loginFailed: null }, () => {

            this.props.setLoading(true);

            const email = this.state.loginEmail.trim();
            const password = this.state.loginPassword;

            fetchUser(email).then(providers => {
                if (providers.length === 0) return Promise.reject(new Error('Email not found.'));
                else return login(email, password);
            }).then(() => {
                this.props.setLoading(false);
            }).catch(error => {
                this.setState({ loginFailed: error.message });
                this.props.setLoading(false);
            });

        });

    }

    signup = e => {

        e.preventDefault();

        this.setState({ signupFailed: null }, () => {

            this.props.setLoading(true);

            const email = this.state.signupEmail.trim();
            const password = this.state.signupPassword;
            const passwordConfirm = this.state.signupPasswordConfirm;

            if (password !== passwordConfirm) {
                this.setState({ signupFailed: 'Passwords do not match.' });
                this.props.setLoading(false);
            } else if (password.length < 6) {
                this.setState({ signupFailed: 'Password must be at least six characters.' });
                this.props.setLoading(false);
            } else {

                fetchUser(email).then(providers => {
                    if (providers.length === 0) return register(email, password);
                    else return Promise.reject(new Error('An account already exists with this email.'));
                }).then(() => {
                    this.setState({ signupFailed: 'Account created successfully. You can now log in with the new account.' });
                    this.props.setLoading(false);
                }).catch(error => {
                    this.setState({ signupFailed: error.message });
                    this.props.setLoading(false);
                });

            }

        });

    }

    renderAuth = () => {

        const { authTab, loginEmail, loginPassword, signupEmail, signupPassword, signupPasswordConfirm, loginFailed, signupFailed } = this.state;

        return (
            <div className="Auth">
                <h1 className="title-treatment">Chili Dip<span>.</span></h1>
                <div>
                    <h2>Ah, Frank!<br />You Chili Dipped it!</h2>
                    <Tab
                        titles={{
                            left: 'Login',
                            right: 'Signup',
                        }}
                        toggleLeft={ this.loginSelected }
                        toggleRight={ this.signupSelected }
                        selected={ authTab }
                    />
                    {
                        authTab === 'left' &&
                        <>
                            <form onSubmit={ this.login }>
                                <Input header="Email" required value={ loginEmail } onChange={ this.onChange } name="loginEmail" />
                                <Input header="Password" type="password" required value={ loginPassword } onChange={ this.onChange } name="loginPassword" />
                                { loginFailed && <h3>{ loginFailed }</h3> }
                                <Button disabled={ !this.isLoginReady() }>Submit</Button>
                            </form>
                        </>
                    }
                    {
                        authTab === 'right' &&
                        <>
                            <form onSubmit={ this.signup }>
                                <Input header="Email" required value={ signupEmail } onChange={ this.onChange } name="signupEmail" />
                                <Input header="Password" type="password" required value={ signupPassword } onChange={ this.onChange } name="signupPassword" />
                                <Input header="Confirm Password" type="password" required value={ signupPasswordConfirm } onChange={ this.onChange } name="signupPasswordConfirm" />
                                { signupFailed && <h3>{ signupFailed }</h3> }
                                <Button disabled={ !this.isSignupReady() }>Submit</Button>
                            </form>
                        </>
                    }
                </div>
            </div>
        );

    };

    renderDashboard = () => (
        <div className="Dashboard">
            <div className="panel">
                <Link to="/play">
                    <><img src="/images/home/play.svg" alt="Play" /><h3>Play</h3></>
                </Link>
                <Link to="/courses">
                    <><img src="/images/home/courses.svg" alt="Courses" /><h3>Courses</h3></>
                </Link>
                <Link to="/rounds">
                    <><img src="/images/home/rounds.svg" alt="Rounds" /><h3>Rounds</h3></>
                </Link>
                <Link to="/tournaments">
                    <><img src="/images/home/tournaments.svg" alt="Tournaments" /><h3>Tournaments</h3></>
                </Link>
                <Link to="/golfers">
                    <><img src="/images/home/golfers.svg" alt="Golfers" /><h3>Golfers</h3></>
                </Link>
                <Link to="/stats">
                    <><img src="/images/home/stats.svg" alt="Stats" /><h3>Stats</h3></>
                </Link>
            </div>
        </div>
    )

    render() {

        const { user } = this.props;

        return (
            <section className="Home">
                { !isLoggedIn(user) && this.renderAuth() }
                { isLoggedIn(user) && this.renderDashboard() }
            </section>
        );

    }

}

export default Home;
