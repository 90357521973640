import CreateTournament from './CreateTournament';
import { connect } from 'react-redux';
import { setPageTitle, setLoading, setModalContent } from '../../redux/actions/app';

const mapStateToProps = state => ({
    user: state.app.user,
});

const mapDispatchToProps = {
    setPageTitle,
    setLoading,
    setModalContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTournament);
