import {
    SET_CONNECTION_STATUS,
    SET_LOADING,
    SET_USER,
    SET_SETTINGS,
    SET_GOLFERS,
    SET_COURSES,
    SET_TOURNAMENTS,
    SET_ROUNDS,
    SET_PAGE_TITLE,
    SET_MODAL_CONTENT,
} from '../actions/types';

const initialState = {
    isConnected: false,
    isLoading: true,
    user: null,
    settings: null,
    golfers: null,
    courses: null,
    tournaments: null,
    rounds: null,
    pageTitle: null,
    prevPageTitle: null,
    modalContent: null,
};

export default function app(state = initialState, action) {

	switch (action.type) {

        case SET_CONNECTION_STATUS:
            return {
                ...state,
                isConnected: action.payload.isConnected,
            };

        case SET_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading,
            };

		case SET_USER:
			return {
                ...state,
                user: action.payload.user,
            };

        case SET_SETTINGS:
            return {
                ...state,
                settings: action.payload.settings,
            };

        case SET_GOLFERS:
            return {
                ...state,
                golfers: action.payload.golfers,
            };

        case SET_COURSES:
            return {
                ...state,
                courses: action.payload.courses,
            };

        case SET_TOURNAMENTS:
            return {
                ...state,
                tournaments: action.payload.tournaments,
            };

        case SET_ROUNDS:
            return {
                ...state,
                rounds: action.payload.rounds,
            };

        case SET_PAGE_TITLE:
            return {
                ...state,
                prevPageTitle: state.pageTitle,
                pageTitle: action.payload.title,
            };

        case SET_MODAL_CONTENT:
            return {
                ...state,
                modalContent: action.payload.content,
            };

		default:
            return state;

    }

}
