// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components.
import GolferRow from '../../../components/GolferRow';
import Notification from '../../../components/Notification';

const GolferList = ({ golfers, search }) => {

    let list = Object.entries(golfers);

    if (search !== '') {
        list = list.filter(golfer => {

            const name = golfer[1].name.toLowerCase();
            const nickname = golfer[1].nickname.toLowerCase();
            const s = search.toLowerCase().trim();

            return (name.includes(s) || nickname.includes(s));

        });
    }

    return (
        <div className="GolferList">
            {
                (list.length === 0) &&
                <Notification>No golfers.</Notification>
            }
            {
                list.map((golfer, key) => (
                    <Link to={ `/golfers/${ golfer[0] }` } key={ key }>
                        <GolferRow
                            primary={ golfer[1].name }
                            secondary={ golfer[1].nickname }
                            icon={ golfer[1].icon }
                        />
                    </Link>
                ))
            }
        </div>
    );

};

GolferList.propTypes = {
    golfers: PropTypes.object,
    search: PropTypes.string,
};

GolferList.defaultProps = {
    golfers: null,
    search: '',
};

export default GolferList;
