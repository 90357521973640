import Home from './Home';
import { connect } from 'react-redux';
import { setLoading, setPageTitle } from '../../redux/actions/app';

const mapStateToProps = state => ({
    isLoading: state.app.isLoading,
    user: state.app.user,
});

const mapDispatchToProps = {
    setLoading,
    setPageTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
