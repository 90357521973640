// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './Notification.scss';

const Notification = props => (
    <div className={ `Notification ${ props.complete && 'complete' }` }>
        <span className="key">{ props.children }</span>
        { props.value !== null && <span className="value">{ props.value }</span> }
        { props.complete ? <img src="/images/icons/check.svg" alt="Complete" /> : null }
    </div>
);

Notification.propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    complete: PropTypes.bool,
};

Notification.defaultProps = {
    value: null,
    complete: false,
};

export default Notification;
