// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import uuid from 'uuid/v4';

// Components.
import SearchBar from '../../components/SearchBar';
import ActionButton from '../../components/ActionButton';

// Elements.
import CourseList from './elements/CourseList';

// Styles.
import './Courses.scss';

// Firebase.
import { createCourse } from '../../firebase/db';

class Courses extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            courses: PropTypes.object,
            setLoading: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            courses: null,
        }
    }

    state = {
        selectedTab: 0,
        name: '',
        location: '',
        teeColor: '',
        slope: '',
        rating: '',
        holes: {},
        failedCreation: null,
        search: '',
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Courses');
    }

    selectTab = selectedTab => {
        this.setState({ selectedTab });
    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value });
    }

    onHoleChange = (hole, par, yardage, strokeIndex) => {
        this.setState({
            holes: {
                ...this.state.holes,
                [hole]: { par, yardage, strokeIndex },
            },
        });
    }

    isCreateCourseReady = () => {

        let ready = true;

        for (let hole of Object.values(this.state.holes)) {
            ready = ((parseInt(hole.par) > 0) && (parseInt(hole.yardage) > 0) && (parseInt(hole.strokeIndex) > 0)) ? true : false;
            if (!ready) break;
        }

        return (
            this.state.name !== '' &&
            this.state.location !== '' &&
            this.state.teeColor !== '' &&
            this.state.slope !== '' &&
            this.state.rating !== '' &&
            Object.keys(this.state.holes).length === 18 &&
            ready
        );
    }

    createCourse = e => {

        e.preventDefault();

        this.setState({ failedCreation: null }, () => {

            this.props.setLoading(true);

            const name = this.state.name.trim();
            const location = this.state.location.trim();
            const teeColor = this.state.teeColor.trim();
            const slope = parseInt(this.state.slope);
            const rating = parseFloat(this.state.rating);

            // Validate the slope.
            if (!(slope >= 55 && slope <= 155)) {

                this.setState({ failedCreation: 'Slope must be between 55 and 155.' });
                this.props.setLoading(false);

                return;

            }

            // Validate the rating.
            if (!(rating >= 1 && rating <= 100)) {

                this.setState({ failedCreation: 'Rating must be between 1 and 100.' });
                this.props.setLoading(false);

                return;

            }

            const strokeIndexes = [];

            for (let hole of Object.values(this.state.holes)) {
                strokeIndexes.push(hole.strokeIndex);
            }

            const distinctStrokeIndexes = [...new Set(strokeIndexes)];

            // Validate that each hole has a different stroke index.
            if (distinctStrokeIndexes.length !== 18) {

                this.setState({ failedCreation: 'One or more holes has the same stroke index.' });
                this.props.setLoading(false);

                return;

            }

            let holes = this.state.holes;

            Object.keys(holes).forEach(key => {
                holes[key].par = parseInt(holes[key].par);
                holes[key].yardage = parseInt(holes[key].yardage);
                holes[key].strokeIndex = parseInt(holes[key].strokeIndex);
            });

            createCourse(this.props.user, uuid(), {
                name,
                location,
                tees: { [teeColor]: {
                    holes,
                    slope,
                    rating,
                }},
            }).then(() => {
                this.props.setLoading(false);
                this.reset();
            });

        });

    }

    reset = () => {
        this.setState({
            selectedTab: 0,
            name: '',
            location: '',
            teeColor: '',
            slope: '',
            rating: '',
            holes: {},
            failedCreation: null,
        });
    }

    onSearchChanged = e => {
        this.setState({ search: e.target.value });
    }

    render() {

        const { search } = this.state;
        const { user, courses } = this.props;

        if (!user || !courses) return <Redirect to="/" />;

        return (
            <section className="Courses">
                {
                    Object.keys(courses).length > 0 &&
                    <SearchBar onChange={ this.onSearchChanged } value={ search } />
                }
                <CourseList courses={ courses } search={ search } />
                <Link to="/courses/create">
                    <ActionButton icon="create" />
                </Link>
            </section>
        );

    }

}

export default Courses;
