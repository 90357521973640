// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components.
import Notification from '../Notification';
import Input from '../Input';
import Button from '../Button';
import ActionButton from '../ActionButton';

// Styles.
import './Menu.scss';

// Firebase.
import { logout } from '../../firebase/auth';
import { updateSettings } from '../../firebase/db';

class Menu extends Component {

    static get propTypes() {
        return {
            toggle: PropTypes.func.isRequired,
            setLoading: PropTypes.func.isRequired,
            setModalContent: PropTypes.func.isRequired,
            user: PropTypes.object,
            settings: PropTypes.object,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            settings: null,
        }
    }

    state = {
        useCalcHdcp: 0,
    }

    componentDidMount() {
        if (this.props.settings) {
            this.setState({
                useCalcHdcp: this.props.settings.useCalculatedHandicap,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Make sure we load the correct state.
        if ((this.props.settings && !prevProps.settings) ||
            ((this.state.isSettingsOpen !== prevState.isSettingsOpen) && this.state.isSettingsOpen)) {
            this.setState({
                useCalcHdcp: this.props.settings.useCalculatedHandicap,
            });
        }
    }

    logout = () => {
        this.props.setLoading(true);
        this.props.toggle();
        logout().then(() => window.location.reload());
    }

    save = e => {

        e.preventDefault();

        updateSettings(this.props.user, {
            useCalculatedHandicap: parseInt(this.state.useCalcHdcp),
        }).then(this.props.toggle);

    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value });
    }

    toggleInfo = () => {
        this.props.setModalContent(
            <>
                <p>If the “Use Calculated Handicaps” field is set to “Yes”, Chili Dip will use a golfer’s calculated handicap rather than their given handicap if they have played 5 or more rounds.</p>
                <p>Press the “exit” icon to logout.</p>
            </>
        );
    }

    render() {

        const { user } = this.props;
        const { useCalcHdcp } = this.state;

        return (
            <section className="Menu">
                <Notification value={ user.email }>Logged in as</Notification>
                <form onSubmit={ this.save }>
                    <Input
                        header="Use Calculated Handicaps"
                        type="select"
                        onChange={ e => this.onChange(e, 'useCalcHdcp') }
                        value={ useCalcHdcp }
                        options={[
                            { label: 'No', value: 0 },
                            { label: 'Yes', value: 1 },
                        ]}
                    />
                    <Button>Save</Button>
                </form>
                <ActionButton icon="info" onClick={ this.toggleInfo } />
                <ActionButton icon="logout" onClick={ this.logout } />
            </section>
        );

    }

}

export default Menu;
