import {
    SET_CONNECTION_STATUS,
    SET_LOADING,
    SET_USER,
    SET_SETTINGS,
    SET_GOLFERS,
    SET_COURSES,
    SET_TOURNAMENTS,
    SET_ROUNDS,
    SET_PAGE_TITLE,
    SET_MODAL_CONTENT,
} from './types';

export const setConnectionStatus = isConnected => ({
    type: SET_CONNECTION_STATUS,
    payload: { isConnected },
});

export const setLoading = isLoading => ({
    type: SET_LOADING,
    payload: { isLoading },
});

export const setUser = user => ({
    type: SET_USER,
    payload: { user },
});

export const setSettings = settings => ({
    type: SET_SETTINGS,
    payload: { settings },
});

export const setGolfers = golfers => ({
    type: SET_GOLFERS,
    payload: { golfers },
});

export const setCourses = courses => ({
    type: SET_COURSES,
    payload: { courses },
});

export const setTournaments = tournaments => ({
    type: SET_TOURNAMENTS,
    payload: { tournaments },
});

export const setRounds = rounds => ({
    type: SET_ROUNDS,
    payload: { rounds },
});

export const setPageTitle = title => ({
    type: SET_PAGE_TITLE,
    payload: { title },
});

export const setModalContent = content => ({
    type: SET_MODAL_CONTENT,
    payload: { content },
});
