// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components.
import SummaryRow from '../../../components/SummaryRow';
import ActionButton from '../../../components/ActionButton';

// Utils.
import { getNetScoreForHoles, getSummaryForLeaders, getSum } from '../../../utils/golf';

class Summary extends Component {

    static get propTypes() {
        return {
            roundId: PropTypes.string,
            round: PropTypes.object,
            golfers: PropTypes.object,
            user: PropTypes.object,
            settings: PropTypes.object,
            setModalContent: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            roundId: null,
            round: null,
            golfers: null,
            user: null,
            settings: null,
        }
    }

    constructor(props) {

        super(props);

        this.state = {
            selectedTeeTime: this.getScorecardOptions()[0].value,
        };

    }

    sortLeaders = () => {

        const { golfers, round } = this.props;

        const holes = round.course.details.tees[round.teeColor].holes;
        const leaders = [];

        if (round.format === 'scramble') {

            for (let teeTime of Object.keys(round.teeTimes)) {

                const team = Object.entries(round.teeTimes[teeTime].golfers).filter(golfer => golfer[1].order === 1)[0];
                const name = golfers[team[0]].name;
                const breakpoint = (name.lastIndexOf(' ') > -1) ? name.lastIndexOf(' ') : 0;
                const teamName = `Team ${ name.substring(breakpoint + 1) }`;
                const scores = team[1].scores;
                const netScore = getNetScoreForHoles(scores, holes, 0);

                leaders.push({
                    id: team[0],
                    name: teamName,
                    nickname: '',
                    icon: null,
                    gross: scores.reduce(getSum),
                    net: netScore.net,
                    scores,
                });

            }

        } else {

            for (let teeTime of Object.keys(round.teeTimes)) {
                for (let golfer of Object.entries(round.teeTimes[teeTime].golfers)) {

                    const scores = golfer[1].scores;
                    const netScore = getNetScoreForHoles(scores, holes, golfer[1].handicap);

                    leaders.push({
                        id: golfer[0],
                        name: golfers[golfer[0]].name,
                        nickname: golfers[golfer[0]].nickname,
                        icon: golfers[golfer[0]].icon,
                        gross: scores.reduce(getSum),
                        net: netScore.net,
                        scores,
                    });

                }
            }

        }

        return getSummaryForLeaders(leaders, holes);

    }

    getScorecardOptions = () => {

        const { round, golfers } = this.props;
        const options = [];

        for (let teeTime of Object.entries(round.teeTimes)) {

            let name = '';

            for (let golfer of Object.entries(teeTime[1].golfers).sort((golferA, golferB) => (golferA[1].order - golferB[1].order))) {
                name += golfers[golfer[0]].name.substring(0, golfers[golfer[0]].name.indexOf(' ')) + ', ';
            }

            name = name.substring(0, name.lastIndexOf(','));

            options.push({
                value: teeTime[0],
                label: name,
            });

        }

        return options;

    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value });
    }

    toggleInfo = () => {
        this.props.setModalContent(
            <>
                <p>Press on a player’s row to expand it and view their round summary.</p>
                <p>Press the row again to collapse it.</p>
            </>
        );
    }

    render() {

        const sortedLeaders = this.sortLeaders();

        return (
            <div className="Summary">
                {
                    sortedLeaders.map((leader, key) => (
                        <SummaryRow golfer={ leader } key={ key } />
                    ))
                }
                <ActionButton icon="info" onClick={ this.toggleInfo } />
            </div>
        );

    }

}

export default Summary;
