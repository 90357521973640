// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components.
import CourseRow from '../../../components/CourseRow';
import Notification from '../../../components/Notification';

// Utils.
import { getCoursePar } from '../../../utils/golf';

const CourseList = ({ courses, search }) => {

    let list = Object.entries(courses);

    if (search !== '') {
        list = list.filter(course => {

            const name = course[1].name.toLowerCase();
            const location = course[1].location.toLowerCase();
            const s = search.toLowerCase().trim();

            return (name.includes(s) || location.includes(s));

        });
    }

    return (
        <div className="CourseList">
            {
                (list.length === 0) &&
                <Notification>No courses.</Notification>
            }
            {
                list.map((course, key) => (
                    <Link to={ `/courses/${ course[0] }` } key={ key }>
                        <CourseRow
                            primary={ course[1].name }
                            secondary={ course[1].location }
                            tertiary={ `Par ${ getCoursePar(course[1]) }` }
                        />
                    </Link>
                ))
            }
        </div>
    );

};

CourseList.propTypes = {
    courses: PropTypes.object,
    search: PropTypes.string,
};

CourseList.defaultProps = {
    courses: null,
    search: '',
};

export default CourseList;
