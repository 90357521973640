// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './Accordion.scss';

// Components.
import Notification from '../Notification';

class Accordion extends React.Component {

    static get propTypes() {
        return {
            children: PropTypes.oneOfType([
                PropTypes.node,
                PropTypes.arrayOf(PropTypes.node)
            ]),
        }
    }

    state = {
        isExpanded: false,
    }

    toggle = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    render() {

        const { children } = this.props;

        return (
            <div className="Accordion">
                <div onClick={ this.toggle }>
                    <Notification value={ this.state.isExpanded ? '-' : '+' }>Matches</Notification>
                </div>
                {
                    this.state.isExpanded &&
                    <div className="panel">
                        { children }
                    </div>
                }
            </div>
        );

    }

}

export default Accordion;
