// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

// Styles.
import './EditGolfer.scss';

// Components.
import Input from '../../components/Input';
import FileInput from '../../components/FileInput';
import Button from '../../components/Button';

// Firebase.
import { createGolfer } from '../../firebase/db';

class EditGolfer extends React.Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            golfers: PropTypes.object,
            setPageTitle: PropTypes.func.isRequired,
            setLoading: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            golfers: null,
        }
    }

    state = {
        name: '',
        nickname: '',
        hometown: '',
        handicap: '',
        icon: undefined,
    }

    golfer = uid => {
        return this.props.golfers ? this.props.golfers[uid] : null;
    }

    componentDidMount() {

        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Edit Golfer');

        const golfer = this.golfer(this.props.match.params.golfer_uid);

        this.setState({
            name: golfer.name,
            nickname: golfer.nickname,
            hometown: golfer.hometown,
            handicap: golfer.givenHandicap,
        });

    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value });
    }

    onFileChange = e => {
        this.setState({ icon: e.target.files[0] });
    }

    isReady = () => {

        const { name, hometown, handicap } = this.state;
        const givenHandicap = parseInt(handicap);

        return (
            name !== '' &&
            hometown !== '' &&
            handicap !== '' &&
            givenHandicap >= 0 &&
            givenHandicap <= 54
        );

    }

    async create(e, self) {

        e.preventDefault();
        self.props.setLoading(true);

        const name = self.state.name.trim();
        const nickname = self.state.nickname.trim();
        const hometown = self.state.hometown.trim();
        const givenHandicap = parseInt(self.state.handicap);
        const icon = self.state.icon ? await self.toBase64(self.state.icon) : null;

        const create = icon => {

            const { golfer_uid } = this.props.match.params;
            const golfer = this.golfer(golfer_uid);

            createGolfer(self.props.user, golfer_uid, { name, nickname, hometown, givenHandicap, icon: icon ? icon : (golfer.icon ? golfer.icon : null) }).then(() => {
                self.props.setLoading(false);
                self.props.history.push(`/golfers`);
            });

        };

        if (icon) {
            axios.post('https://us-central1-chili-dip-2.cloudfunctions.net/upload', {
                image: icon,
            }).then(result => {

                if (!result.data.error) {
                    create(result.data.src);
                } else {
                    // @TODO Handle error.
                    self.props.setLoading(false);
                }

            }).catch(error => {
                // @TODO Handle error.
                console.error(error);
                self.props.setLoading(false);
            });
        } else {
            create(null);
        }

    }

    toBase64 = file => new Promise((resolve, reject) => {

        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);

    })

    render() {

        const { name, nickname, hometown, handicap, icon } = this.state;
        const { user, golfers } = this.props;

        if (!user || !golfers) return <Redirect to="/" />;

        return (
            <div className="EditGolfer">
                <form onSubmit={ e => this.create(e, this) }>
                    <Input type="text" header="Name" onChange={ e => this.onChange(e, 'name') } value={ name } />
                    <Input type="text" header="Nickname" onChange={ e => this.onChange(e, 'nickname') } value={ nickname } required={ false } />
                    <Input type="text" header="Hometown" onChange={ e => this.onChange(e, 'hometown') } value={ hometown } />
                    <Input type="number" header="Handicap" onChange={ e => this.onChange(e, 'handicap') } value={ handicap } />
                    <FileInput filename={ icon ? icon.name : null } onFileChange={ this.onFileChange } />
                    <Button disabled={ !this.isReady() }>Save</Button>
                </form>
            </div>
        );

    }

}

export default EditGolfer;
