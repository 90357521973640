// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import uuid from 'uuid/v4';

// Styles.
import './CreateCourse.scss';

// Components.
import Input from '../../components/Input';
import CreateCourseHeader from '../../components/CreateCourseHeader';
import HoleRow from '../../components/HoleRow';
import Button from '../../components/Button';

// Firebase.
import { createCourse } from '../../firebase/db';

class CreateCourse extends React.Component {

    static get propTypes() {
        return {
            setLoading: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
            user: PropTypes.object,
        }
    }

    static get defaultProps() {
        return {
            user: null,
        }
    }

    state = {
        name: '',
        location: '',
        teeColor: '',
        slope: '',
        rating: '',
        holes: {},
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('New Course');
    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value });
    }

    onHoleChange = (hole, par, yardage, strokeIndex) => {
        this.setState({
            holes: {
                ...this.state.holes,
                [hole]: { par, yardage, strokeIndex },
            }
        });
    }

    isReady = () => {

        let ready = true;

        for (let hole of Object.values(this.state.holes)) {
            ready = ((parseInt(hole.par) > 0) && (parseInt(hole.yardage) > 0) && (parseInt(hole.strokeIndex) > 0));
            if (!ready) break;
        }

        const slope = parseInt(this.state.slope);
        const rating = parseFloat(this.state.rating);

        // Validate the slope.
        if (!(slope >= 55 && slope <= 155)) {
            ready = false;
        }

        // Validate the rating.
        if (!(rating >= 1 && rating <= 100)) {
            ready = false;
        }

        const strokeIndexes = [];

        for (let hole of Object.values(this.state.holes)) {
            strokeIndexes.push(hole.strokeIndex);
        }

        const distinctStrokeIndexes = [...new Set(strokeIndexes)];

        // Validate stroke indexes (for uniqueness).
        if (distinctStrokeIndexes.length !== 18) {
            ready = false;
        }

        return (
            this.state.name !== '' &&
            this.state.location !== '' &&
            this.state.teeColor !== '' &&
            this.state.slope !== '' &&
            this.state.rating !== '' &&
            Object.keys(this.state.holes).length === 18 &&
            ready
        );

    }

    save = e => {

        e.preventDefault();
        this.props.setLoading(true);

        const name = this.state.name.trim();
        const location = this.state.location.trim();
        const teeColor = this.state.teeColor.trim();
        const slope = parseInt(this.state.slope);
        const rating = parseFloat(this.state.rating);

        let holes = this.state.holes;

        Object.keys(holes).forEach(key => {
            holes[key].par = parseInt(holes[key].par);
            holes[key].yardage = parseInt(holes[key].yardage);
            holes[key].strokeIndex = parseInt(holes[key].strokeIndex);
        });

        createCourse(this.props.user, uuid(), {
            name,
            location,
            tees: { [teeColor]: {
                holes,
                slope,
                rating,
            }},
        }).then(() => {
            this.props.setLoading(false);
            this.props.history.push('/courses');
        });

    }

    render() {

        const { name, location, teeColor, slope, rating, holes } = this.state;
        const { user } = this.props;

        if (!user) return <Redirect to="/" />;

        return (
            <section className="CreateCourse">
                <form onSubmit={ this.save }>
                    <Input type="text" header="Name" value={ name } onChange={ e => this.onChange(e, 'name') } />
                    <Input type="text" header="Location" value={ location } onChange={ e => this.onChange(e, 'location') } />
                    <Input type="text" header="Tee Color" value={ teeColor } onChange={ e => this.onChange(e, 'teeColor') } />
                    <Input type="number" header="Slope" value={ slope } onChange={ e => this.onChange(e, 'slope') } />
                    <Input type="number" header="Rating" value={ rating } onChange={ e => this.onChange(e, 'rating') } />
                    <CreateCourseHeader />
                    {
                        Array(18).fill().map((_, i) => (
                            <HoleRow
                                hole={ i + 1 }
                                onHoleChange={ this.onHoleChange }
                                par={ (holes[i + 1] && parseInt(holes[i + 1].par)) || 0 }
                                yardage={ (holes[i + 1] && holes[i + 1].yardage) || '' }
                                strokeIndex={ (holes[i + 1] && parseInt(holes[i + 1].strokeIndex)) || 0 }
                                key={ i }
                            />
                        ))
                    }
                    <Button disabled={ !this.isReady() }>Save</Button>
                </form>
            </section>
        );

    }

}

export default CreateCourse;
