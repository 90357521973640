// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './Modal.scss';

// Components.
import Button from '../Button';

const Modal = props => (
    <div className={ `Modal ${ props.content && 'visible' }` }>
        <div className="panel">
            <div className="copy">
                { props.content }
                <div className="corner" />
                <div className="corner" />
                <div className="corner" />
                <div className="corner" />
            </div>
            <Button onClick={ () => props.setModalContent(null) }>Close</Button>
        </div>
    </div>
);

Modal.propTypes = {
    content: PropTypes.node,
    setModalContent: PropTypes.func.isRequired,
};

Modal.defaultProps = {
    content: null,
};

export default Modal;
