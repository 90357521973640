// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './GolferRow.scss';

const GolferRow = props => (
    <div className="GolferRow">
        <div className="left">
            {
                props.icon ?
                <span className="icon" style={{ backgroundImage: `url('${ props.icon }')` }}></span> :
                <span className="icon">{ props.primary.substring(0, 1) }</span>
            }
            <div>
                <h2>{ props.primary }{ props.super !== null && <sup>{ props.super }</sup> }</h2>
                { props.secondary && <h3>{ props.secondary }</h3> }
            </div>
        </div>
        <div className="right">
            { props.tertiary !== null && !props.complete && <h2>{ props.tertiary }</h2> }
        </div>
    </div>
);

GolferRow.propTypes = {
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string,
    tertiary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    super: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.string,
};

GolferRow.defaultProps = {
    secondary: null,
    tertiary: null,
    super: null,
    icon: null,
};

export default GolferRow;
