// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './CourseRow.scss';

const CourseRow = props => (
    <div className={ `CourseRow ${ props.complete && 'complete' }` }>
        <div className="left">
            <span className="icon">{ props.primary.substring(0, 1) }</span>
            <div>
                <h2>{ props.primary }</h2>
                { props.secondary && <h3>{ props.secondary }</h3> }
            </div>
        </div>
        <div className="right">
            { props.tertiary && !props.complete && <h2>{ props.tertiary }</h2> }
            { props.complete && <img src="/images/icons/check.svg" alt="Complete" /> }
        </div>
    </div>
);

CourseRow.propTypes = {
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string,
    tertiary: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    complete: PropTypes.bool,
};

CourseRow.defaultProps = {
    secondary: null,
    tertiary: null,
    complete: false,
};

export default CourseRow;
