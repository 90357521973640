// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';

// Components.
import Tab from '../../components/Tab';
import ActionButton from '../../components/ActionButton';

// Elements.
import ActiveList from './elements/ActiveList';

// Styles.
import './Play.scss';

class Play extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            courses: PropTypes.object,
            tournaments: PropTypes.object,
            rounds: PropTypes.object,
            setLoading: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            courses: null,
            tournaments: null,
            rounds: null,
        }
    }

    state = {
        playTab: 'left',
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Play');
    }

    roundsSelected = () => {
        this.setState({ playTab: 'left' });
    }

    tournamentsSelected = () => {
        this.setState({ playTab: 'right' });
    }

    render() {

        const { playTab } = this.state;
        const { user, courses, tournaments, rounds } = this.props;

        if (!user || !courses) return <Redirect to="/" />;

        return (
            <section className="Play">
                <Tab
                    titles={{
                        left: 'Rounds',
                        right: 'Tournaments',
                    }}
                    toggleLeft={ this.roundsSelected }
                    toggleRight={ this.tournamentsSelected }
                    selected={ playTab }
                />
                {
                    playTab === 'left' &&
                    <ActiveList rounds={ rounds } />
                }
                { playTab === 'left' && <Link to="/play/create"><ActionButton icon="create" /></Link> }
                {
                    playTab === 'right' &&
                    <ActiveList rounds={ rounds } tournaments={ tournaments } listType="tournaments" />
                }
            </section>
        );

    }

}

export default Play;
