// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Components.
import CourseRow from '../../../components/CourseRow';
import Notification from '../../../components/Notification';

// Utils.
import { getTournamentRounds } from '../../../utils/golf';

const TournamentList = ({ tournaments, rounds }) => (
    <div className="TournamentList">
        {
            Object.entries(tournaments).filter(tournament => tournament[1].complete).length === 0 &&
            <Notification>No tournaments.</Notification>
        }
        {
            Object.entries(tournaments).filter(tournament => tournament[1].complete).map((tournament, key) => {

                const tournamentRounds = getTournamentRounds(tournament[0], rounds);

                return (
                    <Link to={ `/tournaments/${ tournament[0] }` } key={ key }>
                        <CourseRow
                            primary={ tournament[1].name }
                            secondary={ `${ tournamentRounds.length } ${ tournamentRounds.length === 1 ? 'Round' : 'Rounds' }` }
                        />
                    </Link>
                );

            })
        }
    </div>
);

TournamentList.propTypes = {
    tournaments: PropTypes.object,
    rounds: PropTypes.object,
};

TournamentList.defaultProps = {
    tournaments: null,
    rounds: null,
};

export default TournamentList;
