// Modules.
import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

// Styles.
import './LeaderboardRow.scss';

// Utils.
import { getSum } from '../../utils/golf';

class LeaderboardRow extends React.Component {

    static get propTypes() {
        return {
            leader: PropTypes.object.isRequired,
            isTournament: PropTypes.bool,
            isTournamentResults: PropTypes.bool,
            linkOut: PropTypes.bool,
        }
    }

    static get defaultProps() {
        return {
            isTournament: false,
            isTournamentResults: false,
            linkOut: false,
        }
    }

    state = {
        isExpanded: false,
    }

    toggle = () => {

        // Bail if it's an unbalanced tournament.
        if (!this.props.leader.name) return;
        this.setState({ isExpanded: !this.state.isExpanded });

    }

    renderHoles = (start, end, sum) => (
        <div className="holes">
            {
                Array(10).fill().map((_, i) => {
                    if (i === (end - start)) return <span key={ i }>{ sum }</span>;
                    return <span key={ i }>{ i + start }</span>
                })
            }
        </div>
    )

    renderPars = (start, end) => {

        const { holes } = this.props.leader;

        return (
            <div className="pars">
                {
                    Array(10).fill().map((_, i) => {

                        if (i === (end - start)) return (
                            <span key={ i }>{ holes.slice(start, end).map(hole => hole.par).reduce(getSum) }</span>
                        );

                        return <span key={ i }>{ holes[i + start].par }</span>;

                    })
                }
            </div>
        );

    }

    renderScores = (start, end) => {

        const { scores, holes } = this.props.leader;
        let s = [];

        if (scores && !Array.isArray(scores)) {
            for (let [key, value] of Object.entries(scores)) {
                s[key] = value;
            }
        } else {
            s = scores;
        }

        const hasScores = s && s.slice(start, end).length > 0 && !(s.slice(start, end).every(v => (v === null)));

        return (
            <div className="scores">
                {
                    Array(10).fill().map((_, i) => {

                        if (i === (end - start)) return (
                            <span key={ i }>{ hasScores ? s.slice(start, end).reduce(getSum) : '-' }</span>
                        );

                        if (!s) return <span key={ i }>-</span>;

                        return <span className={ this.getMarkClass(s[i + start], holes[i + start].par) } key={ i }><span>{ s[i + start] || '-' }</span></span>

                    })
                }
            </div>
        );

    }

    getMarkClass = (score, par) => {
        switch (true) {
            default:
            case (!score || (score === par)):
                return '';
            case (score - par === -1):
                return 'birdie';
            case (score - par < -1):
                return 'eagle';
            case (score - par === 1):
                return 'bogey';
            case (score - par > 1):
                return 'double';
        }
    }

    render() {

        const { leader, isTournament, isTournamentResults, linkOut } = this.props;
        const { isExpanded } = this.state;

        return (
            <div className={ `LeaderboardRow ${ isTournamentResults && 'results' } ${ linkOut && 'linkOut' }` }>
                <div className="scoreRow" onClick={ this.toggle }>
                    <div className="golfer">
                        <h3 className={ leader.position === '' ? 'hidden' : '' }>{ leader.position }</h3>
                        {
                            leader.icon ?
                            <span className="icon" style={{ backgroundImage: `url('${ leader.icon }')` }}></span> :
                            <span className="icon">{ leader.name ? leader.name.substring(0, 1) : '-' }</span>
                        }
                        <div className="name">
                            <h4>{ leader.name }</h4>
                            { leader.nickname && <h5>{ leader.nickname }</h5> }
                        </div>
                    </div>
                    <div className="tallies">
                        {
                            !isTournamentResults &&
                            <div>
                                <h5>{ isTournament ? leader.tournamentScoreToPar : leader.scoreToPar }</h5>
                                <h5>{ (leader.holesPlayed === 18) ? 'F' : leader.holesPlayed }</h5>
                                <h5>{ isTournament ? leader.scoreToPar : leader.gross }</h5>
                            </div>
                        }
                        {
                            isTournamentResults &&
                            <div className="results">
                                {/* {
                                    leader.gross.map((total, key) => (
                                        <h5 key={ key }>{ total }</h5>
                                    ))
                                } */}
                                <h5>{ leader.tournamentScoreToPar }</h5>
                            </div>
                        }
                    </div>
                </div>
                {
                    isExpanded && !isTournamentResults &&
                    <div className="cardRow">
                        { this.renderHoles(1, 10, 'Out') }
                        { this.renderPars(1, 10) }
                        { this.renderScores(1, 10) }
                        { this.renderHoles(10, 19, 'In') }
                        { this.renderPars(10, 19) }
                        { this.renderScores(10, 19) }
                    </div>
                }
                {
                    isExpanded && isTournamentResults &&
                    <div className="roundsRow">
                        {
                            leader.gross.map((total, key) => (
                                <div key={ key }>
                                    <h4>Round { key + 1 }</h4>
                                    <h5>{ total }</h5>
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
        );

    }

}

export default LeaderboardRow;
