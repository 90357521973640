import Stats from './Stats';
import { connect } from 'react-redux';
import { setPageTitle } from '../../redux/actions/app';

const mapStateToProps = state => ({
    user: state.app.user,
    golfers: state.app.golfers,
    rounds: state.app.rounds,
    courses: state.app.courses,
});

const mapDispatchToProps = {
    setPageTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
