// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Styles.
import './ScorecardScore.scss';

class ScorecardScore extends Component {

    static get propTypes() {
        return {
            holes: PropTypes.array.isRequired,
            golfer: PropTypes.array.isRequired,
            start: PropTypes.number.isRequired,
            stop: PropTypes.number.isRequired,
            openScoreSelector: PropTypes.func.isRequired,
            settings: PropTypes.object,
            isScramble: PropTypes.bool,
        };
    }

    static get defaultProps() {
        return {
            settings: null,
            isScramble: false,
        }
    }

    hole = () => ((this.props.start === this.props.stop) ? this.props.holes[this.props.start] : null)

    getSum = (total, num) => (total + num);

    getScores = () => {

        const { start, stop, golfer } = this.props;
        const scores = [];

        for (let i = start; i <= stop; i++) {
            golfer[1].scores[i] && scores.push(golfer[1].scores[i]);
        }

        return scores;

    }

    getStrokesGiven = () => {

        const { start, stop, golfer, holes } = this.props;
        let strokesGiven = 0;

        if (start === stop) {

            const hole = this.hole(start, stop);
            const diff = golfer[1].handicap - hole.strokeIndex;

            strokesGiven += this.getStrokesGivenFromDiff(diff);

        } else {

            for (let i = start; i <= stop; i++) {
                const diff = (golfer[1].scores && golfer[1].scores[i] && golfer[1].handicap - holes[i].strokeIndex);
                strokesGiven += this.getStrokesGivenFromDiff(diff);
            }

        }

        return strokesGiven;

    }

    getStrokesGivenFromDiff = diff => {
        switch (true) {
            case diff >= 36:
                return 3;
            case diff >= 18:
                return 2;
            case diff >= 0:
                return 1;
            default:
                return 0;
        }
    }

    openScoreSelector = () => {

        const { start, stop, golfer } = this.props;

        if (start === stop) {
            this.props.openScoreSelector(golfer[0], start);
        }

    }

    getClassForShape = shape => {
        switch (true) {
            case shape <= -2:
                return 'eagle';
            case shape === -1:
                return 'birdie';
            case shape === 1:
                return 'bogey';
            case shape >= 2:
                return 'double';
            default:
                return 'par';
        }
    }

    render() {

        const { golfer, isScramble } = this.props;

        const hole = this.hole();
        const scores = golfer[1].scores ? this.getScores() : [];
        const gross = (scores.length > 0) ? scores.reduce(this.getSum) : 0;
        const strokesGiven = this.getStrokesGiven();

        const shape = (hole && scores.length > 0) ? (gross - hole.par) : 0;
        const shapeClass = this.getClassForShape(shape);

        return (
            <div className="ScorecardScore" onClick={ this.openScoreSelector }>
                {
                    !isScramble &&
                    <div className="dots">
                        { hole && ((golfer[1].handicap - hole.strokeIndex) >= 0) && <div /> }
                        { hole && ((golfer[1].handicap - hole.strokeIndex) >= 18) && <div /> }
                        { hole && ((golfer[1].handicap - hole.strokeIndex) >= 36) && <div /> }
                    </div>
                }
                {
                    (!isScramble || golfer[1].order === 1) &&
                    <div className={ `gross ${ shapeClass }` }>
                        { (gross > 0) ? gross : '' }
                    </div>
                }
                {
                    !isScramble &&
                    <div className="net">
                        { (gross > 0) ? (gross - strokesGiven) : '' }
                    </div>
                }
            </div>
        );

    }

}

export default ScorecardScore;
