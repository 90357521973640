// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './ActionButton.scss';

const ActionButton = props => (
    <button className="ActionButton" data-id={ props.icon } onClick={ props.onClick }>
        <img src={ `/images/icons/${ props.icon }.svg` } alt="Icon" />
    </button>
);

ActionButton.propTypes = {
    icon: PropTypes.oneOf(['info', 'logout', 'create', 'edit']).isRequired,
    onClick: PropTypes.func,
};

ActionButton.defaultProps = {
    onClick: f => f,
};

export default ActionButton;
