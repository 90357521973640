// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './LeaderboardHeader.scss';

const LeaderboardHeader = ({ isTournament, isTournamentResults, count, format }) => {

    const rounds = [];
    let header = 'Players';

    for (let i = 1; i <= count; i++) {
        rounds.push(`R${ i }`);
    }

    switch (format) {
        default:
        case 'stroke':
            header = 'Players';
            break;
        case 'yellowball':
        case 'bestball':
            header = 'Players / Teams';
            break;
        case 'scramble':
            header = 'Teams';
            break;
    }

    return (
        <div className={ `LeaderboardHeader ${ isTournamentResults && 'results' }` }>
            <h4>{ header }</h4>
            <div className="tallies">
                {
                    !isTournamentResults &&
                    <div>
                        <h4>Score</h4>
                        <h4>Thru</h4>
                        <h4>{ isTournament ? 'Today' : 'Tot' }</h4>
                    </div>
                }
                {
                    isTournamentResults &&
                    <div className="results">
                        {/* {
                            rounds.map((round, key) => (
                                <h4 key={ key }>{ round }</h4>
                            ))
                        } */}
                        <h4>Score</h4>
                    </div>
                }
            </div>
        </div>
    );

};

LeaderboardHeader.propTypes = {
    isTournament: PropTypes.bool,
    isTournamentResults: PropTypes.bool,
    count: PropTypes.number,
    format: PropTypes.string,
};

LeaderboardHeader.defaultProps = {
    isTournament: false,
    isTournamentResults: false,
    count: 0,
    format: 'stroke',
};

export default LeaderboardHeader;
