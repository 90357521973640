import CreateCourse from './CreateCourse';
import { connect } from 'react-redux';
import { setPageTitle, setLoading } from '../../redux/actions/app';

const mapStateToProps = state => ({
    user: state.app.user,
});

const mapDispatchToProps = {
    setPageTitle,
    setLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourse);
