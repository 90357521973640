// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import TimeField from 'react-simple-timefield';

// Styles.
import './Input.scss';

const Input = ({ type, header, value, onChange, options, required, disabled, step, style, name, placeholder, min, max }) => (
    <div className="Input" style={ style }>
        { header && <h4>{ header }</h4> }
        {
            (type === 'text' || type === 'password' || type === 'number') &&
            <input type={ type } placeholder={ placeholder } autoComplete="off" required={ required } value={ value } onChange={ onChange } disabled={ disabled } step={ step } name={ name } min={ min } max={ max } />
        }
        {
            type === 'time' &&
            <TimeField
                value={ value }
                onChange={ onChange }
            />
        }
        {
            type === 'select' &&
            <select onChange={ onChange } dir="rtl" value={ value } disabled={ disabled }>
                {
                    options.map((option, key) => (
                        <option value={ option.value } key={ key }>{ option.label }</option>
                    ))
                }
            </select>
        }
    </div>
);

Input.propTypes = {
    type: PropTypes.oneOf(['text', 'password', 'number', 'select', 'time']),
    header: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    options: PropTypes.array,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    step: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
    placeholder: PropTypes.string,
};

Input.defaultProps = {
    type: 'text',
    header: null,
    value: '',
    onChange: f => f,
    options: [],
    required: true,
    disabled: false,
    step: '0.01',
    min: '0',
    max: '9999',
    style: {},
    name: '',
    placeholder: '-',
};

export default Input;
