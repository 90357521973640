import Tournament from './Tournament';
import { connect } from 'react-redux';
import { setPageTitle, setModalContent } from '../../redux/actions/app';

const mapStateToProps = state => ({
    user: state.app.user,
    tournaments: state.app.tournaments,
    rounds: state.app.rounds,
    golfers: state.app.golfers,
});

const mapDispatchToProps = {
    setPageTitle,
    setModalContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);
