import Menu from './Menu';
import { connect } from 'react-redux';
import { setLoading, setModalContent } from '../../redux/actions/app';

const mapStateToProps = state => ({
    user: state.app.user,
    settings: state.app.settings,
});

const mapDispatchToProps = {
    setLoading,
    setModalContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
