// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import ScorecardScore from '../ScorecardScore';

// Styles.
import './ScorecardHole.scss';

// Utils.
import { getSum } from '../../utils/golf';

const ScorecardHole = ({ holes, holeNumber, total, teeTime, start, stop, openScoreSelector, settings, hideDetails, isScramble }) => (
    <div className="ScorecardHole">
        {
            !hideDetails &&
            <div className={ `holeDetails ${ total && 'total' }` }>
                {
                    !total &&
                    <>
                        <span>Hole { holeNumber }</span>
                        <span>Par <strong>{ holes[holeNumber].par }</strong></span>
                        <span>Yds <strong>{ holes[holeNumber].yardage }</strong></span>
                        <span>SI <strong>{ holes[holeNumber].strokeIndex }</strong></span>
                    </>
                }
                {
                    total &&
                    <>
                        <span className="sum">{ holeNumber }</span>
                        <span>Par <strong>{ holes.slice(start, stop + 1).map(hole => hole.par).reduce(getSum) }</strong></span>
                        <span>Yds <strong>{ holes.slice(start, stop + 1).map(hole => hole.yardage).reduce(getSum) }</strong></span>
                        <span></span>
                    </>
                }
            </div>
        }
        <div className={ `hole ${ total && 'total' }` }>
            {
                Object.entries(teeTime.golfers).sort((golferA, golferB) => golferA[1].order - golferB[1].order).map((golfer, key) => (
                    <ScorecardScore
                        holes={ holes }
                        golfer={ golfer }
                        start={ start }
                        stop={ stop }
                        key={ key }
                        openScoreSelector={ openScoreSelector }
                        settings={ settings }
                        isScramble={ isScramble }
                    />
                ))
            }
            {
                Object.keys(teeTime.golfers).length < 4 &&
                [1, 2, 3].slice(0, 4 - Object.keys(teeTime.golfers).length).map((dummy, key) => (
                    <div className="dummy" key={ key } />
                ))
            }
        </div>
    </div>
);

ScorecardHole.propTypes = {
    holes: PropTypes.array.isRequired,
    holeNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    total: PropTypes.bool,
    teeTime: PropTypes.object.isRequired,
    start: PropTypes.number.isRequired,
    stop: PropTypes.number.isRequired,
    openScoreSelector: PropTypes.func,
    settings: PropTypes.object,
    hideDetails: PropTypes.bool,
    isScramble: PropTypes.bool,
};

ScorecardHole.defaultProps = {
    total: false,
    openScoreSelector: f => f,
    settings: null,
    hideDetails: false,
    isScramble: false,
};

export default ScorecardHole;
