// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './ScorecardSticky.scss';

const ScorecardSticky = ({ teeTime, golfers }) => (
    <div className="ScorecardSticky">
        {
            Object.entries(teeTime.golfers).sort((golferA, golferB) => golferA[1].order - golferB[1].order).map((golfer, key) => (
                <div className="golfer" key={ key }>
                    <h3>{ golfers[golfer[0]].name }</h3>
                    <div className="handicap">
                        <div className="dot" />
                        <span>{ golfer[1].handicap }</span>
                    </div>
                </div>
            ))
        }
        {
            Object.keys(teeTime.golfers).length < 4 &&
            [1, 2, 3].slice(0, 4 - Object.keys(teeTime.golfers).length).map((dummy, key) => (
                <div className="golfer dummy" key={ key } />
            ))
        }
    </div>
);

ScorecardSticky.propTypes = {
    teeTime: PropTypes.object,
    golfers: PropTypes.object,
};

ScorecardSticky.defaultProps = {
    teeTime: null,
    golfers: null,
};

export default ScorecardSticky;
