// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Components.
import Input from '../../components/Input';
import GolferRow from '../../components/GolferRow';
import Notification from '../../components/Notification';

// Styles.
import './Stats.scss';

// Utils.
import { getRoundsForGolfer, getGrossScoreForRound, getRoundDetailsForGolfer } from '../../utils/golf';

class Stats extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            golfers: PropTypes.object,
            rounds: PropTypes.object,
            courses: PropTypes.object,
            setPageTitle: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            golfers: null,
            rounds: null,
            courses: null,
        }
    }

    state = {
        selectedStatistic: 'scoring_average',
        selectedModifier: 'total',
        selectedCourse: '-1',
        isModifierAvailable: false,
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Stats');
    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value }, () => {
            switch (this.state.selectedStatistic) {
                default:
                case 'scoring_average':
                case 'front_nine':
                case 'back_nine':
                case 'par_3':
                case 'par_4':
                case 'par_5':
                case 'low_gross':
                    this.setState({ isModifierAvailable: false });
                    break;
                case 'eagles':
                case 'birdies':
                case 'pars':
                    this.setState({ isModifierAvailable: true });
                    break;
            }
        });
    }

    qualifiedGolfers = isPerRound => {

        const { golfers, rounds } = this.props;

        if (isPerRound) {
            return Object.entries(golfers).filter(golfer => {
                return (getRoundsForGolfer(rounds, golfer[0], true, this.state.selectedCourse, true).length >= 1);
            });
        }

        return Object.entries(golfers);

    }

    statistic = () => {
        switch (this.state.selectedStatistic) {
            case 'scoring_average':
                return this.scoringAverage();
            case 'front_nine':
                return this.frontNine();
            case 'back_nine':
                return this.backNine();
            case 'par_3':
                return this.par3();
            case 'par_4':
                return this.par4();
            case 'par_5':
                return this.par5();
            case 'low_gross':
                return this.lowGross();
            case 'eagles':
                return this.eagles();
            case 'birdies':
                return this.birdies();
            case 'pars':
                return this.pars();
            default:
                return null;
        }
    }

    scoringAverage = () => {

        const { rounds } = this.props;
        const qualifiedGolfers = this.qualifiedGolfers(true);
        const list = [];

        for (let golfer of qualifiedGolfers) {

            const qualifiedRounds = getRoundsForGolfer(rounds, golfer[0], true, this.state.selectedCourse, true);
            let sum = 0;

            for (let round of qualifiedRounds) {
                sum += getGrossScoreForRound(round, golfer[0]);
            }

            list.push({
                ...golfer[1],
                statistic: (sum / qualifiedRounds.length).toFixed(2),
            });

        }

        return list.sort((a, b) => (a.statistic - b.statistic));

    }

    scoringAverageRange = (start, stop) => {

        const { rounds } = this.props;
        const qualifiedGolfers = this.qualifiedGolfers(true);
        const list = [];

        for (let golfer of qualifiedGolfers) {

            const qualifiedRounds = getRoundsForGolfer(rounds, golfer[0], true, this.state.selectedCourse, true);
            let sum = 0;

            for (let round of qualifiedRounds) {

                const details = getRoundDetailsForGolfer(round, golfer[0]);

                for (let i = start; i <= stop; i++) {
                    sum += details.scores[i];
                }

            }

            list.push({
                ...golfer[1],
                statistic: (sum / qualifiedRounds.length).toFixed(2),
            });

        }

        return list.sort((a, b) => (a.statistic - b.statistic));

    }

    frontNine = () => {
        return this.scoringAverageRange(1, 9);
    }

    backNine = () => {
        return this.scoringAverageRange(10, 18);
    }

    scoringAveragePar = par => {

        const { rounds } = this.props;
        const qualifiedGolfers = this.qualifiedGolfers(true);
        const list = [];

        for (let golfer of qualifiedGolfers) {

            const qualifiedRounds = getRoundsForGolfer(rounds, golfer[0], true, this.state.selectedCourse, true);
            let sum = 0;
            let count = 0;

            for (let round of qualifiedRounds) {

                const details = getRoundDetailsForGolfer(round, golfer[0]);

                for (let i = 1; i <= 18; i++) {
                    if (round.course.details.tees[round.teeColor].holes[i].par === par) {
                        sum += details.scores[i];
                        count++;
                    }
                }

            }

            list.push({
                ...golfer[1],
                statistic: (sum / count).toFixed(2),
            });

        }

        return list.sort((a, b) => (a.statistic - b.statistic));

    }

    par3 = () => {
        return this.scoringAveragePar(3);
    }

    par4 = () => {
        return this.scoringAveragePar(4);
    }

    par5 = () => {
        return this.scoringAveragePar(5);
    }

    scoreToPar = score => {

        const { rounds } = this.props;
        const qualifiedGolfers = this.qualifiedGolfers(false);
        const list = [];

        for (let golfer of qualifiedGolfers) {

            const qualifiedRounds = getRoundsForGolfer(rounds, golfer[0], true, this.state.selectedCourse, true);
            let sum = 0;

            for (let round of qualifiedRounds) {

                const details = getRoundDetailsForGolfer(round, golfer[0]);

                for (let i = 1; i <= 18; i++) {

                    const gross = details.scores[i];
                    const scoreToPar = (gross - round.course.details.tees[round.teeColor].holes[i].par);

                    if (scoreToPar === score) sum++;

                }

            }

            if (this.state.selectedModifier === 'total') {
                if (qualifiedRounds.length >= 1) {
                    list.push({
                        ...golfer[1],
                        statistic: sum,
                    });
                }
            } else {
                if (qualifiedRounds.length >= 1) {
                    list.push({
                        ...golfer[1],
                        statistic: (sum / qualifiedRounds.length).toFixed(2),
                    });
                }
            }

        }

        return list.sort((a, b) => (b.statistic - a.statistic));

    }

    eagles = () => {
        return this.scoreToPar(-2);
    }

    birdies = () => {
        return this.scoreToPar(-1);
    }

    pars = () => {
        return this.scoreToPar(0);
    }

    lowGross = () => {

        const { rounds } = this.props;
        const qualifiedGolfers = this.qualifiedGolfers(false);
        const list = [];

        for (let golfer of qualifiedGolfers) {

            const qualifiedRounds = getRoundsForGolfer(rounds, golfer[0], true, this.state.selectedCourse, true);
            let scores = [];

            for (let round of qualifiedRounds) {
                scores.push(getGrossScoreForRound(round, golfer[0]));
            }

            if (qualifiedRounds.length >= 1) {
                list.push({
                    ...golfer[1],
                    statistic: Math.min(...scores),
                });
            }

        }

        return list.sort((a, b) => (a.statistic - b.statistic));

    }

    courseOptions = () => {

        const options = Object.entries(this.props.courses).map((course, key) => (
            { label: course[1].name, value: course[0] }
        ));

        options.unshift({ label: 'All', value: '-1' });

        return options;

    }

    render() {

        const { selectedStatistic, selectedModifier, selectedCourse, isModifierAvailable } = this.state;
        const { user, golfers, rounds, courses } = this.props;

        if (!user || !golfers || !rounds || !courses) return <Redirect to="/" />;

        return (
            <section className="Stats">
                <Input
                    type="select"
                    options={ this.courseOptions() }
                    header="Course"
                    value={ selectedCourse }
                    onChange={ e => this.onChange(e, 'selectedCourse') }
                />
                <Input
                    type="select"
                    options={[
                        { label: 'Scoring Average - 18 Holes', value: 'scoring_average' },
                        { label: 'Scoring Average - Front Nine', value: 'front_nine' },
                        { label: 'Scoring Average - Back Nine', value: 'back_nine' },
                        { label: 'Scoring Average - Par 3s', value: 'par_3' },
                        { label: 'Scoring Average - Par 4s', value: 'par_4' },
                        { label: 'Scoring Average - Par 5s', value: 'par_5' },
                        { label: 'Low Gross', value: 'low_gross' },
                        { label: 'Eagles', value: 'eagles' },
                        { label: 'Birdies', value: 'birdies' },
                        { label: 'Pars', value: 'pars' },
                    ]}
                    header="Statistic"
                    value={ selectedStatistic }
                    onChange={ e => this.onChange(e, 'selectedStatistic') }
                />
                {
                    isModifierAvailable &&
                    <Input
                        type="select"
                        options={[
                            { label: 'Total', value: 'total' },
                            { label: 'Per Round', value: 'per_round' },
                        ]}
                        header="Modifier"
                        value={ selectedModifier }
                        onChange={ e => this.onChange(e, 'selectedModifier') }
                    />
                }
                <div className="golfers">
                    {
                        (this.statistic().length === 0) &&
                        <Notification>No qualified golfers.</Notification>
                    }
                    {
                        this.statistic().map((golfer, key) => (
                            <GolferRow
                                primary={ golfer.name }
                                secondary={ golfer.nickname }
                                tertiary={ golfer.statistic }
                                icon={ golfer.icon }
                                key={ key }
                            />
                        ))
                    }
                </div>
            </section>
        );

    }

}

export default Stats;
