export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
export const SET_LOADING = 'SET_LOADING';
export const SET_USER = 'SET_USER';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_GOLFERS = 'SET_GOLFERS';
export const SET_COURSES = 'SET_COURSES';
export const SET_TOURNAMENTS = 'SET_TOURNAMENTS';
export const SET_ROUNDS = 'SET_ROUNDS';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT';
