// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './MatchPreview.scss';

// Components.
import GolferRow from '../GolferRow';

// Misc.
import { getNetScoreForHole, getRoundDetailsForGolfer, getMatchPlayRecordForGolfer } from '../../utils/golf';

const getStatus = (diff, holesRemaining, team) => {

    // The match is over.
    if (Math.abs(diff) > holesRemaining) {
        if ((diff > 0 && team === 'one') || (diff < 0 && team === 'two')) return holesRemaining === 0 ? `${ Math.abs(diff) } UP` : `${ Math.abs(diff) } & ${ holesRemaining }`;
        if ((diff > 0 && team === 'two') || (diff < 0 && team === 'one')) return `-`;
    } else {
        if (holesRemaining === 0) return 'Halved';
        if (diff === 0) return 'AS';
        if ((diff > 0 && team === 'one') || (diff < 0 && team === 'two')) return `${ Math.abs(diff) } UP`;
        if ((diff > 0 && team === 'two') || (diff < 0 && team === 'one')) return `-`;
    }

};

const getThru = (holes, holesRemaining, diff) => {

    let thru = '';

    if (Math.abs(diff) > holesRemaining || holesRemaining === 0) {
        thru = 'Final';
    } else {

        if (holes === 'full-18') thru = `Thru ${ (18 - holesRemaining) }`;
        else thru = `Thru ${ (9 - holesRemaining) }`;

        // Dormie.
        if (Math.abs(diff) === holesRemaining) thru += ' (Dormie)';

    }

    return thru;

};

const MatchPreview = props => {

    let holes = 'Full 18';

    switch (props.match.holes) {
        default:
        case 'full-18':
            holes = 'Full 18';
            break;
        case 'front-9':
            holes = 'Front 9';
            break;
        case 'back-9':
            holes = 'Back 9';
            break;
    }

    let diff = 0;
    let holesRemaining = 18;

    if (props.match.holes !== 'full-18') holesRemaining = 9;
    if (props.round) {
        for (let i = 1; i <= 18; i++) {

            if (props.match.holes === 'front-9' && i >= 10) continue;
            if (props.match.holes === 'back-9' && i <= 9) continue;

            const hole = props.round.course.details.tees[props.round.teeColor].holes[i];
            const getTeamScore = team => {

                let score = 0;

                for (let golfer of Object.entries(props.match.golfers).filter(golfer => golfer[1].team === team)) {

                    const details = getRoundDetailsForGolfer(props.round, golfer[0]);

                    if (details.scores) {
                        score += details.scores[i] ? getNetScoreForHole(details.scores[i], hole, (props.match.holes === 'full-18' ? golfer[1].strokes : (golfer[1].strokes * 2))) : 0;
                    }

                }

                return score;

            };

            const team1Score = getTeamScore(1);
            const team2Score = getTeamScore(2);

            if (team1Score > 0 && team2Score > 0) {
                if (team1Score > team2Score) diff--;
                if (team1Score < team2Score) diff++;
                holesRemaining--;
            }

            // Bail out!
            if (Math.abs(diff) > holesRemaining) break;

        }
    }

    return (
        <div className={ `MatchPreview ${ props.isLeaderboard && 'leaderboard' }` }>
            <div className="header">
                <h3>{ holes }</h3>
                { props.isLeaderboard && <h3>{ getThru(props.match.holes, holesRemaining, diff) }</h3> }
                { props.thru && <h3>{ props.thru }</h3> }
            </div>
            <div className="golfers">
                <div className="team">
                    {
                        Object.entries(props.match.golfers).filter(golfer => golfer[1].team === 1).map((golfer, key) => (
                            <GolferRow
                                primary={ props.golfers[golfer[0]].name }
                                secondary={ props.golfers[golfer[0]].nickname }
                                tertiary={ props.isLeaderboard ? '' : getMatchPlayRecordForGolfer(props.rounds, golfer[0]) }
                                super={ golfer[1].strokes }
                                icon={ props.golfers[golfer[0]].icon }
                                key={ key }
                            />
                        ))
                    }
                    { props.isLeaderboard && <div className="score">{ getStatus(diff, holesRemaining, 'one') }</div> }
                </div>
                <div className="versus"><span>VS</span></div>
                <div className="team">
                    {
                        Object.entries(props.match.golfers).filter(golfer => golfer[1].team === 2).map((golfer, key) => (
                            <GolferRow
                                primary={ props.golfers[golfer[0]].name }
                                secondary={ props.golfers[golfer[0]].nickname }
                                tertiary={ props.isLeaderboard ? '' : getMatchPlayRecordForGolfer(props.rounds, golfer[0]) }
                                super={ golfer[1].strokes }
                                icon={ props.golfers[golfer[0]].icon }
                                key={ key }
                            />
                        ))
                    }
                    { props.isLeaderboard && <div className="score">{ getStatus(diff, holesRemaining, 'two') }</div> }
                </div>
            </div>
        </div>
    );

};

MatchPreview.propTypes = {
    match: PropTypes.object.isRequired,
    golfers: PropTypes.object.isRequired,
    round: PropTypes.object,
    rounds: PropTypes.object,
    isLeaderboard: PropTypes.bool,
    thru: PropTypes.string,
};

MatchPreview.defaultProps = {
    isLeaderboard: false,
    round: null,
    rounds: null,
    thru: null,
};

export default MatchPreview;
