// Get the par of a course's tee.
export const getCoursePar = (course, teeColor) => {

    if (!teeColor) {
        teeColor = Object.keys(course.tees)[0];
    }

    const holes = Object.values(course.tees[teeColor].holes);
    let par = 0;

    for (let hole of holes) {
        par += hole.par;
    }

    return par;

};

// Get the yardage of a course's tee.
export const getCourseYardage = (course, teeColor) => {

    const holes = Object.values(course.tees[teeColor].holes);
    let yardage = 0;

    for (let hole of holes) {
        yardage += hole.yardage;
    }

    return yardage;

};

// Get all the rounds associated with a tournament.
export const getTournamentRounds = (tournament, rounds) => {
    return Object.entries(rounds).filter(round => {
        return (round[1].tournament === tournament);
    });
};

export const getSum = (total, num) => (total + num);

export const getGrossScoreForRound = (round, golferId) => {
    for (let teeTime of Object.values(round.teeTimes)) {
        if (Object.keys(teeTime.golfers).includes(golferId)) {
            return teeTime.golfers[golferId].scores.reduce(getSum);
        }
    }
};

export const getRoundDetailsForGolfer = (round, golferId) => {
    for (let teeTime of Object.values(round.teeTimes)) {
        if (Object.keys(teeTime.golfers).includes(golferId)) {
            return teeTime.golfers[golferId];
        }
    }
};

export const getNetScoreForHoles = (scores, holes, handicap) => {

    const netScore = Object.entries(scores).map(score => (
        getNetScoreForHole(score[1], holes[score[0]], handicap
    ))).reduce(getSum);

    const scoreToPar = netScore - getParForHoles(scores, holes);

    return {
        net: scoreToPar,
        scoreToPar: formatScoreToPar(scoreToPar),
    };

};

export const formatScoreToPar = scoreToPar => {
    switch (true) {
        case scoreToPar > 0:
            return `+${ scoreToPar }`;
        case scoreToPar === 0:
            return 'E';
        default:
            return scoreToPar;
    }
};

export const getNetScoreForHole = (score, hole, handicap) => {

    const diff = (handicap - hole.strokeIndex);

    switch (true) {
        case diff >= 36:
            return score - 3;
        case diff >= 18:
            return score - 2;
        case diff >= 0:
            return score - 1;
        default:
            return score;
    }

};

export const getParForHoles = (scores, holes) => (
    Object.entries(scores).map(score => (
        holes[score[0]].par
    )).reduce(getSum)
);

export const getSummaryForLeaders = (leaders, holes) => {

    for (let leader of leaders) {

        const summary = {
            eagles: 0,
            birdies: 0,
            pars: 0,
            bogeys: 0,
            doubles: 0,
            triples: 0,
        };

        for (let i = 1; i <= 18; i++) {

            const net = (leader.scores[i] - holes[i].par);

            switch (true) {
                case net <= -2:
                    summary.eagles++;
                    break;
                case net === -1:
                    summary.birdies++;
                    break;
                case net === 1:
                    summary.bogeys++;
                    break;
                case net === 2:
                    summary.doubles++;
                    break;
                case net >= 3:
                    summary.triples++;
                    break;
                default:
                    summary.pars++;
                    break;
            }

        }

        leader.summary = summary;

    }

    return Object.values(leaders).sort((leaderA, leaderB) => (leaderA.gross - leaderB.gross));

}

export const getStandingsForLeaders = (leaders, roundId) => {

    const combinedLeaders = {};

    for (let l of leaders) {

        const rId = Object.keys(l)[0];
        const golfers = Object.values(l)[0];

        for (let leader of golfers) {

            if (combinedLeaders[leader.id]) {
                combinedLeaders[leader.id].tournamentNet += leader.net;
                combinedLeaders[leader.id].tournamentScoreToPar = formatScoreToPar(combinedLeaders[leader.id].tournamentNet);
            } else {
                combinedLeaders[leader.id] = {
                    tournamentNet: leader.net,
                    tournamentScoreToPar: leader.scoreToPar,
                };
            }

            // Make sure our "today" tallies are showing correctly.
            if (rId === roundId) {
                combinedLeaders[leader.id] = {
                    ...combinedLeaders[leader.id],
                    ...leader,
                }
            }

        }
    }

    const sortedLeaders = Object.values(combinedLeaders).sort((leaderA, leaderB) => (leaderA.tournamentNet - leaderB.tournamentNet));
    const standings = [];
    let sumPos = 0;
    let prevNet = NaN;

    // Determine how many players have each score.
    for (let leader of sortedLeaders) {

        let score = standings.filter(s => s.score === leader.tournamentNet);

        if (score.length > 0) score[0].count++;
        else standings.push({ score: leader.tournamentNet, count: 1 });

    }

    // Loop over the unique scores and assign
    // position values to them.
    for (let i = 0; i < standings.length; i++) {
        standings[i].position = `${ sumPos + 1 }`;
        sumPos += standings[i].count;
    }

    // Modify the existing leaders to include position.
    for (let leader of sortedLeaders) {

        let score = standings.filter(s => s.score === leader.tournamentNet);

        leader.position = (prevNet !== leader.tournamentNet) ? score[0].position : '';
        prevNet = leader.tournamentNet;

    }

    return sortedLeaders;

};

export const getStandingsForTournament = leaders => {

    const combinedLeaders = {};

    for (let l of leaders) {

        const golfers = Object.values(l)[0];

        for (let leader of golfers) {
            if (combinedLeaders[leader.id]) {
                combinedLeaders[leader.id].tournamentNet += leader.net;
                combinedLeaders[leader.id].tournamentScoreToPar = formatScoreToPar(combinedLeaders[leader.id].tournamentNet);
                combinedLeaders[leader.id].gross.push(leader.gross);
            } else {
                combinedLeaders[leader.id] = {
                    tournamentNet: leader.net,
                    tournamentScoreToPar: leader.scoreToPar,
                    gross: [leader.gross],
                    name: leader.name,
                    nickname: leader.nickname,
                    icon: leader.icon,
                };
            }
        }
    }

    const sortedLeaders = Object.values(combinedLeaders).sort((leaderA, leaderB) => (leaderA.tournamentNet - leaderB.tournamentNet));
    const standings = [];
    let sumPos = 0;
    let prevNet = NaN;

    // Determine how many players have each score.
    for (let leader of sortedLeaders) {

        let score = standings.filter(s => s.score === leader.tournamentNet);

        if (score.length > 0) score[0].count++;
        else standings.push({ score: leader.tournamentNet, count: 1 });

    }

    // Loop over the unique scores and assign
    // position values to them.
    for (let i = 0; i < standings.length; i++) {
        standings[i].position = `${ sumPos + 1 }`;
        sumPos += standings[i].count;
    }

    // Modify the existing leaders to include position.
    for (let leader of sortedLeaders) {

        let score = standings.filter(s => s.score === leader.tournamentNet);

        leader.position = (prevNet !== leader.tournamentNet) ? score[0].position : '';
        prevNet = leader.tournamentNet;

    }

    return sortedLeaders;

};

export const getRoundsForCourse = (rounds, courseId) => (
    Object.values(rounds).filter(round => (round.complete === 1 && round.course.uid === courseId && round.format !== 'scramble'))
);

export const getLowScoresForCourse = (rounds, golfers, courseId, isGross = true) => {

    const courseRounds = getRoundsForCourse(rounds, courseId);
    let allScores = [];

    for (let round of courseRounds) {
        for (let teeTime of Object.values(round.teeTimes)) {
            for (let golfer of Object.entries(teeTime.golfers)) {

                const name = golfers[golfer[0]].name;
                const gross = golfer[1].scores.reduce(getSum);
                const net = (gross - golfer[1].handicap);
                const par = getCoursePar(round.course.details, round.teeColor);
                const scoreToPar = (net - par);

                allScores.push({ name, gross, net, scoreToPar: formatScoreToPar(scoreToPar) });

            }
        }
    }

    return isGross ? allScores.sort((scoreA, scoreB) => (scoreA.gross - scoreB.gross))[0] : allScores.sort((scoreA, scoreB) => (scoreA.net - scoreB.net))[0];

};

export const getRoundListForGolfer = (rounds, golferId) => {

    const golferRounds = {};

    for (let round of Object.entries(rounds).filter(round => round[1].complete === 1)) {
        for (let teeTime of Object.values(round[1].teeTimes)) {
            if (Object.keys(teeTime.golfers).includes(golferId)) {
                golferRounds[round[0]] = round[1];
            }
        }
    }

    return golferRounds;

}

export const getRoundsForGolfer = (rounds, golferId, isHandicap = false, courseId = '-1', override = false) => {

    const golferRounds = [];
    let filteredRounds = Object.values(rounds);

    if (courseId !== '-1') {
        filteredRounds = filteredRounds.filter(round => round.course.uid === courseId);
    }

    for (let round of filteredRounds.filter(round => {

        if (isHandicap) {
            return (round.complete === 1 && round.format !== 'scramble');
        }

        return (round.complete === 1);

    })) {

        if (isHandicap && (getCoursePar(round.course.details, round.teeColor) < 70) && (!override || courseId === '-1')) {
            continue;
        }

        for (let teeTime of Object.values(round.teeTimes)) {
            if (Object.keys(teeTime.golfers).includes(golferId)) {
                golferRounds.push(round);
            }
        }

    }

    return golferRounds;

};

export const getHandicapForGolfer = (rounds, golferId, isProfile = false) => {

    let qualifiedRounds = getRoundsForGolfer(rounds, golferId, true).sort((a, b) => (b.date - a.date)).slice(0, 20);
    const differentials = [];

    for (let round of qualifiedRounds) {

        const gross = getGrossScoreForRound(round, golferId);
        const differential = getDifferentialForRound(round, gross);

        differentials.push(differential);

    }

    const sortedDifferentials = differentials.sort((a, b) => (a - b));
    const x = sortedDifferentials.length;
    let factoredRounds;

    switch (true) {
        default:
        case x <= 6:
            factoredRounds = sortedDifferentials.slice(0, 1);
            break;
        case x <= 8:
            factoredRounds = sortedDifferentials.slice(0, 2);
            break;
        case x <= 10:
            factoredRounds = sortedDifferentials.slice(0, 3);
            break;
        case x <= 12:
            factoredRounds = sortedDifferentials.slice(0, 4);
            break;
        case x <= 14:
            factoredRounds = sortedDifferentials.slice(0, 5);
            break;
        case x <= 16:
            factoredRounds = sortedDifferentials.slice(0, 6);
            break;
        case x === 17:
            factoredRounds = sortedDifferentials.slice(0, 7);
            break;
        case x >= 18:
            factoredRounds = sortedDifferentials.slice(0, 8);
            break;
        // case x === 19:
            // factoredRounds = sortedDifferentials.slice(0, 9);
            // break;
        // case x === 20:
            // factoredRounds = sortedDifferentials.slice(0, 10);
            // break;
    }

    if (isProfile) return (factoredRounds.length > 0) ? Math.min(54, (factoredRounds.reduce(getSum) / factoredRounds.length)) : null;

    // return (factoredRounds.length > 0) ? Math.min(36, ((factoredRounds.reduce(getSum) / factoredRounds.length) * .96)) : null;
    return (factoredRounds.length > 0) ? Math.min(54, Math.max((factoredRounds.reduce(getSum) / factoredRounds.length), 0)) : null;

};

export const getDifferentialForRound = (round, gross) => {

    const slope = round.course.details.tees[round.teeColor].slope;
    const rating = round.course.details.tees[round.teeColor].rating;

    return ((gross - rating) * (113 / slope));

};

export const getMatchPlayRecordForGolfer = (rounds, golferId) => {

    const matches = [];

    for (let round of Object.values(rounds).filter(round => round.complete)) {
        if (round.matches) {
            for (let match of Object.values(round.matches)) {
                if (Object.keys(match.golfers).includes(golferId)) {
                    matches.push({ round, match });
                }
            }
        }
    }

    if (matches.length === 0) return '0-0-0';

    let wins = 0;
    let losses = 0;
    let halves = 0;

    for (let match of matches) {

        let diff = 0;
        let holesRemaining = 18;

        if (match.match.holes !== 'full-18') holesRemaining = 9;

        for (let i = 1; i <= 18; i++) {

            if (match.match.holes === 'front-9' && i >= 10) continue;
            if (match.match.holes === 'back-9' && i <= 9) continue;

            const hole = match.round.course.details.tees[match.round.teeColor].holes[i];
            const getTeamScore = team => {

                let score = 0;

                for (let golfer of Object.entries(match.match.golfers).filter(golfer => golfer[1].team === team)) {

                    const details = getRoundDetailsForGolfer(match.round, golfer[0]);

                    if (details.scores) {
                        score += details.scores[i] ? getNetScoreForHole(details.scores[i], hole, (match.match.holes === 'full-18' ? golfer[1].strokes : (golfer[1].strokes * 2))) : 0;
                    }

                }

                return score;

            };

            const team1Score = getTeamScore(1);
            const team2Score = getTeamScore(2);

            if (team1Score > 0 && team2Score > 0) {
                if (team1Score > team2Score) diff--;
                if (team1Score < team2Score) diff++;
                holesRemaining--;
            }

            // Bail out!
            if (Math.abs(diff) > holesRemaining) break;

        }

        const teamId = match.match.golfers[golferId].team;

        if (diff === 0) halves++;
        else if ((diff > 0 && teamId === 1) || (diff < 0 && teamId === 2)) wins++;
        else losses++;

    }

    return `${ wins }-${ losses }-${ halves }`;

};
