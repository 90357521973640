// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import uuid from 'uuid/v4';

// Styles.
import './CreateTournament.scss';

// Components.
import Input from '../../components/Input';
import Button from '../../components/Button';

// Firebase.
import { createTournament } from '../../firebase/db';

class CreateTournament extends React.Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            setModalContent: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
            setLoading: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
        }
    }

    state = {
        name: '',
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('New Tournament');
    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value });
    }

    create = e => {

        e.preventDefault();

        this.props.setLoading(true);

        const name = this.state.name.trim();

        createTournament(this.props.user, uuid(), { name }).then(() => {
            this.props.setLoading(false);
            this.props.history.push(`/tournaments`);
        });

    }

    render() {

        const { user } = this.props;
        const { name } = this.state;

        if (!user) return <Redirect to="/" />;

        return (
            <section className="CreateTournament">
                <form onSubmit={ this.create }>
                    <Input type="text" name="name" value={ name } onChange={ e => this.onChange(e, 'name') } header="Name" />
                    <Button disabled={ name === '' }>Save</Button>
                </form>
            </section>
        );

    }

}

export default CreateTournament;
