import Golfers from './Golfers';
import { connect } from 'react-redux';
import { setLoading, setPageTitle } from '../../redux/actions/app';

const mapStateToProps = state => ({
    user: state.app.user,
    golfers: state.app.golfers,
});

const mapDispatchToProps = {
    setLoading,
    setPageTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Golfers);
