// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Components.
import Leaderboard from '../../components/Leaderboard';
import Tab from '../../components/Tab';

// Elements.
import Summary from './elements/Summary';

// Styles.
import './Round.scss';

class Round extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            golfers: PropTypes.object,
            rounds: PropTypes.object,
            settings: PropTypes.object,
            setModalContent: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            golfers: null,
            rounds: null,
            settings: null,
        }
    }

    state = {
        roundTab: 'left',
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Round Review');
    }

    round = uid => (
        this.props.rounds ? this.props.rounds[uid] : null
    )

    leaderboardSelected = () => {
        this.setState({ roundTab: 'left' });
    }

    summarySelected = () => {
        this.setState({ roundTab: 'right' });
    }

    render() {

        const { roundTab } = this.state;
        const { user, golfers, rounds, match, settings, setModalContent } = this.props;

        const round = this.round(match.params.round_uid);

        if (!user || !golfers || !rounds || !round.complete) return <Redirect to="/" />;

        return (
            <section className="Round">
                <div className="tabWrapper">
                    <Tab
                        titles={{
                            left: 'Leaderboard',
                            right: 'Summary',
                        }}
                        toggleLeft={ this.leaderboardSelected }
                        toggleRight={ this.summarySelected }
                        selected={ roundTab }
                    />
                </div>
                {
                    roundTab === 'left' &&
                    <Leaderboard
                        golfers={ golfers }
                        round={ round }
                        roundId={ match.params.round_uid }
                        rounds={ rounds }
                        setModalContent={ setModalContent }
                    />
                }
                {
                    roundTab === 'right' &&
                    <Summary
                        round={ round }
                        golfers={ golfers }
                        user={ user }
                        settings={ settings }
                        roundId={ match.params.round_uid }
                        setModalContent={ setModalContent }
                    />
                }
            </section>
        );

    }

}

export default Round;
