// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './Button.scss';

const Button = ({ children, onClick, disabled }) => (
    <button className="Button" onClick={ onClick } disabled={ disabled }>{ children }</button>
);

Button.propTypes = {
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

Button.defaultProps = {
    onClick: f => f,
    disabled: false,
};

export default Button;
