// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';

// Components.
import ActionButton from '../../components/ActionButton';

// Elements.
import TournamentList from './elements/TournamentList';

// Styles.
import './Tournaments.scss';

class Tournaments extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            tournaments: PropTypes.object,
            rounds: PropTypes.object,
            setModalContent: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            tournaments: null,
            rounds: null,
        }
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Tournaments');
    }

    toggleInfo = () => {
        this.props.setModalContent(
            <>
                <p>Press the "plus" icon to create a new tournament.</p>
                <p>New tournaments will not appear in this list until they are marked as completed. To add a new round to a tournament, create a new round and select the appropriate tournament from the list.</p>
                <p>Tournaments cannot be marked as completed until at least one round associated with the tournament has been marked as completed.</p>
            </>
        );
    }

    render() {

        const { user, tournaments, rounds } = this.props;

        if (!user || !tournaments || !rounds) return <Redirect to="/" />;

        return (
            <section className="Tournaments">
                <TournamentList tournaments={ tournaments } rounds={ rounds } />
                <ActionButton icon="info" onClick={ this.toggleInfo } />
                <Link to="/tournaments/create">
                    <ActionButton icon="create" />
                </Link>
            </section>
        );

    }

}

export default Tournaments;
