// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import { Redirect } from 'react-router-dom';

// Styles.
import './CreateRound.scss';

// Components.
import CourseRow from '../../components/CourseRow';
import Notification from '../../components/Notification';
import Input from '../../components/Input';
import Button from '../../components/Button';
import ActionButton from '../../components/ActionButton';
import SearchBar from '../../components/SearchBar';

// Utils.
import { getCoursePar } from '../../utils/golf';

// Firebase.
import { createRound } from '../../firebase/db';

class CreateRound extends React.Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            courses: PropTypes.object,
            tournaments: PropTypes.object,
            setLoading: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
            setModalContent: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            courses: null,
            tournaments: null,
        }
    }

    state = {
        selectedCourse: null,
        selectedTeeColor: '',
        selectedFormat: '',
        selectedTournament: '',
        search: '',
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('New Round / Select Course');
    }

    selectCourse = selectedCourse => {
        this.setState({ selectedCourse });
        this.props.setPageTitle('New Round / Select Details');
    }

    onSearchChanged = e => {
        this.setState({ search: e.target.value });
    };

    onChange = (e, id) => {
        this.setState({
            [id]: e.target.value,
        });
    }

    createRound = e => {

        e.preventDefault();

        this.props.setLoading(true);

        const teeColor = this.state.selectedTeeColor;
        const format = this.state.selectedFormat;
        const tournament = this.state.selectedTournament;
        const date = new Date();

        createRound(this.props.user, uuid(), {
            teeColor,
            format,
            tournament: format !== 'scramble' ? tournament : '',
            course: {
                uid: this.state.selectedCourse[0],
                details: this.state.selectedCourse[1],
            },
            date: date.getTime(),
        }).then(() => {
            this.props.setLoading(false);
            this.props.history.push('/play');
        });

    }

    isReady = () => (this.state.selectedTeeColor && this.state.selectedFormat)

    toggleInfo = () => {
        this.props.setModalContent(
            <>
                <p>Leave the “Tournament” field empty to create an independent round or select a tournament from the list to associate this round with the selected tournament.</p>
            </>
        );
    }

    render() {

        const { user, courses, tournaments } = this.props;
        const { selectedCourse, search, selectedTeeColor, selectedFormat, selectedTournament } = this.state;

        if (!user || !courses) return <Redirect to="/" />;

        let list = Object.entries(courses);

        if (search !== '') {
            list = list.filter(course => {

                const name = course[1].name.toLowerCase();
                const location = course[1].location.toLowerCase();
                const s = search.toLowerCase().trim();

                return (name.includes(s) || location.includes(s));

            });
        }

        return (
            <section className="CreateRound">
                {
                    !selectedCourse &&
                    <>
                        <SearchBar onChange={ this.onSearchChanged } value={ search } />
                        {
                            (list.length === 0) &&
                            <Notification>No courses.</Notification>
                        }
                        {
                            list.map((course, key) => (
                                <span onClick={ () => this.selectCourse(course) } key={ key }>
                                    <CourseRow
                                        primary={ course[1].name }
                                        secondary={ course[1].location }
                                        tertiary={ `Par ${ getCoursePar(course[1]) }` }
                                    />
                                </span>
                            ))
                        }
                    </>
                }
                {
                    selectedCourse &&
                    <>
                        <Notification value={ selectedCourse[1].name }>Course</Notification>
                        <form onSubmit={ this.createRound }>
                            <Input
                                type="select"
                                options={[
                                    { label: '-', value: '' },
                                    ...Object.keys(selectedCourse[1].tees).map(tee => ({ label: tee, value: tee })),
                                ]}
                                header="Tee Color"
                                value={ selectedTeeColor }
                                onChange={ e => this.onChange(e, 'selectedTeeColor') }
                            />
                            <Input
                                type="select"
                                options={[
                                    { label: '-', value: '' },
                                    { label: 'Stroke Play', value: 'stroke' },
                                    { label: 'Yellowball', value: 'yellowball' },
                                    { label: 'Scramble', value: 'scramble' },
                                    { label: 'Best Ball', value: 'bestball' },
                                ]}
                                header="Format"
                                value={ selectedFormat }
                                onChange={ e => this.onChange(e, 'selectedFormat') }
                            />
                            <Input
                                type="select"
                                options={[
                                    { label: '-', value: '' },
                                    ...Object.entries(tournaments).filter(tournament => !tournament[1].complete).map(tournament => ({ label: tournament[1].name, value: tournament[0] }))
                                ]}
                                header="Tournament"
                                value={ selectedTournament }
                                onChange={ e => this.onChange(e, 'selectedTournament') }
                                disabled={ selectedFormat === 'scramble' }
                                style={{ opacity: (selectedFormat === 'scramble') ? .3 : 1 }}
                            />
                            <Button disabled={ !this.isReady() }>Save</Button>
                        </form>
                        <ActionButton icon="info" onClick={ this.toggleInfo } />
                    </>
                }
            </section>
        );

    }

}

export default CreateRound;
