// Modules.
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
    apiKey: "AIzaSyBmwS_s-qVMTUxAlPa7EzjI4gMCp9mAPqg",
    authDomain: "chili-dip-2.firebaseapp.com",
    databaseURL: "https://chili-dip-2.firebaseio.com",
    projectId: "chili-dip-2",
    storageBucket: "chili-dip-2.appspot.com",
    messagingSenderId: "763543384848"
};

firebase.initializeApp(config);

export const database = firebase.database();
export const auth = firebase.auth();
