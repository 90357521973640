import Tournaments from './Tournaments';
import { connect } from 'react-redux';
import { setModalContent, setPageTitle } from '../../redux/actions/app';

const mapStateToProps = state => ({
    user: state.app.user,
    tournaments: state.app.tournaments,
    rounds: state.app.rounds,
});

const mapDispatchToProps = {
    setPageTitle,
    setModalContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tournaments);
