// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './Tab.scss';

const Tab = props => (
    <div className="Tab">
        <div className={ `left ${ props.selected === 'left' && 'selected' }` } onClick={ props.toggleLeft }>
            <span>{ props.titles.left }</span>
        </div>
        <div className={ `right ${ props.selected === 'right' && 'selected' }` } onClick={ props.toggleRight }>
            <span>{ props.titles.right }</span>
        </div>
    </div>
);

Tab.propTypes = {
    titles: PropTypes.object,
    toggleLeft: PropTypes.func,
    toggleRight: PropTypes.func,
    selected: PropTypes.oneOf(['left', 'right'])
};

Tab.defaultProps = {
    titles: { left: 'Left', right: 'Right' },
    toggleLeft: f => f,
    toggleRight: f => f,
    selected: 'left',
};

export default Tab;
