// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';

// Styles.
import './EditCourse.scss';

// Components.
import Tab from '../../components/Tab';
import Input from '../../components/Input';
import Button from '../../components/Button';
import ActionButton from '../../components/ActionButton';
import CreateCourseHeader from '../../components/CreateCourseHeader';
import HoleRow from '../../components/HoleRow';

// Firebase.
import { createCourse } from '../../firebase/db';

class EditCourse extends React.Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            courses: PropTypes.object,
            setLoading: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
            setModalContent: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            courses: null,
        }
    }

    constructor(props) {

        super(props);

        const course = this.course(this.props.match.params.course_uid);

        this.state = {
            editCourseTab: 'left',
            name: course ? course.name : '',
            location: course ? course.location : '',
            selectedTeeColor: course ? Object.keys(course.tees)[0] : null,
            slope: course ? Object.values(course.tees)[0].slope : '',
            rating: course ? Object.values(course.tees)[0].rating : '',
            holes: course ? Object.values(course.tees)[0].holes : {},
        };

    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Edit Course');
    }

    course = uid => {
        return this.props.courses ? this.props.courses[uid] : null;
    }

    infoSelected = () => {
        this.setState({ editCourseTab: 'left' });
    }

    teeSelected = () => {
        this.setState({ editCourseTab: 'right' });
    }

    editCourse = e => {

        e.preventDefault();

        const { course_uid } = this.props.match.params;

        const name = this.state.name.trim();
        const location = this.state.location.trim();
        const course = this.course(course_uid);

        this.props.setLoading(true);

        createCourse(this.props.user, course_uid, { ...course, name, location }).then(() => {
            this.props.setLoading(false);
            this.props.history.push(`/courses/${ course_uid }`);
        });

    }

    editTeeColor = e => {

        e.preventDefault();
        this.props.setLoading(true);

        const teeColor = this.state.selectedTeeColor;
        const slope = parseInt(this.state.slope);
        const rating = parseFloat(this.state.rating);
        const { course_uid } = this.props.match.params;
        const course = this.course(course_uid);

        let holes = this.state.holes;

        Object.keys(holes).forEach(key => {
            holes[key].par = parseInt(holes[key].par);
            holes[key].yardage = parseInt(holes[key].yardage);
            holes[key].strokeIndex = parseInt(holes[key].strokeIndex);
        });

        createCourse(this.props.user, course_uid, {
            ...course,
            tees: {
                ...course.tees,
                [teeColor]: {
                    holes,
                    slope,
                    rating,
                },
            },
        }).then(() => {
            this.props.setLoading(false);
            this.props.history.push(`/courses/${ course_uid }`);
        });

    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value });
    }

    onTeeChange = e => {

        const course = this.course(this.props.match.params.course_uid);

        this.setState({
            selectedTeeColor: e.target.value,
            slope: course.tees[e.target.value].slope,
            rating: course.tees[e.target.value].rating,
            holes: course.tees[e.target.value].holes,
        });

    }

    onHoleChange = (hole, par, yardage, strokeIndex) => {
        this.setState({
            holes: {
                ...this.state.holes,
                [hole]: { par, yardage, strokeIndex },
            }
        });
    }

    editCourseReady = () => (
        this.state.name !== '' &&
        this.state.location !== ''
    )

    editTeeColorReady = () => {

        let ready = true;

        for (let hole of Object.values(this.state.holes)) {
            ready = ((parseInt(hole.par) > 0) && (parseInt(hole.yardage) > 0) && (parseInt(hole.strokeIndex) > 0));
            if (!ready) break;
        }

        const slope = parseInt(this.state.slope);
        const rating = parseFloat(this.state.rating);

        // Validate the slope.
        if (!(slope >= 55 && slope <= 155)) {
            ready = false;
        }

        // Validate the rating.
        if (!(rating >= 1 && rating <= 100)) {
            ready = false;
        }

        const strokeIndexes = [];

        for (let hole of Object.values(this.state.holes)) {
            strokeIndexes.push(parseInt(hole.strokeIndex));
        }

        const distinctStrokeIndexes = [...new Set(strokeIndexes)];

        // Validate stroke indexes (for uniqueness).
        if (distinctStrokeIndexes.length !== 18) {
            ready = false;
        }

        return (
            this.state.slope !== '' &&
            this.state.rating !== '' &&
            Object.keys(this.state.holes).length === 18 &&
            ready
        );

    }

    toggleInfo = () => {
        this.props.setModalContent(
            <>
                <p>Press the “plus” icon to add a new tee color to this course.</p>
            </>
        );
    }

    render() {

        const { editCourseTab, name, location, slope, rating, selectedTeeColor, holes } = this.state;
        const { user, match } = this.props;
        const course = this.course(match.params.course_uid);

        if (!user || !course) return <Redirect to="/courses" />;

        return (
            <section className="EditCourse">
                <Tab
                    titles={{
                        left: 'Info',
                        right: 'Tee',
                    }}
                    toggleLeft={ this.infoSelected }
                    toggleRight={ this.teeSelected }
                    selected={ editCourseTab }
                />
                {
                    editCourseTab === 'left' &&
                    <>
                        <form onSubmit={ this.editCourse }>
                            <Input type="text" value={ name } header="Name" onChange={ e => this.onChange(e, 'name') } />
                            <Input type="text" value={ location } header="Location" onChange={ e => this.onChange(e, 'location') } />
                            <Button disabled={ !this.editCourseReady() }>Save</Button>
                        </form>
                    </>
                }
                {
                    editCourseTab === 'right' &&
                    <>
                        <form onSubmit={ this.editTeeColor }>
                            <Input
                                type="select"
                                value={ selectedTeeColor }
                                options={ Object.keys(course.tees).map(tee => ({ label: tee, value: tee })) }
                                header="Tee Color"
                                onChange={ e => this.onTeeChange(e) }
                            />
                            <Input type="number" value={ slope } header="Slope" onChange={ e => this.onChange(e, 'slope') } />
                            <Input type="number" value={ rating } header="Rating" onChange={ e => this.onChange(e, 'rating') } />
                            <CreateCourseHeader />
                            {
                                Array(18).fill().map((_, i) => (
                                    <HoleRow
                                        hole={ i + 1 }
                                        onHoleChange={ this.onHoleChange }
                                        par={ (holes[i + 1] && parseInt(holes[i + 1].par)) || 0 }
                                        yardage={ (holes[i + 1] && holes[i + 1].yardage) || '' }
                                        strokeIndex={ (holes[i + 1] && parseInt(holes[i + 1].strokeIndex)) || 0 }
                                        key={ i }
                                    />
                                ))
                            }
                            <Button disabled={ !this.editTeeColorReady() }>Save</Button>
                        </form>
                    </>
                }
                <ActionButton icon="info" onClick={ this.toggleInfo } />
                <Link to={ `/courses/${ match.params.course_uid }/create` }>
                    <ActionButton icon="create" />
                </Link>
            </section>
        );

    }

}

export default EditCourse;
