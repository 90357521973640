// Modules.
import React from 'react';

// Styles.
import './Loader.scss';

const Loader = () => (
    <div className="Loader">
        <div className="circle" />
    </div>
);

export default Loader;
