// Modules.
import React from 'react';

// Styles.
import './ConnectionWarning.scss';

// Components.
import Button from '../Button';

const ConnectionWarning = () => {

    const reload = () => { window.location.reload() };

    return (
        <div className="ConnectionWarning">
            <img src="/images/icons/warning.png" alt="Warning" />
            <h3>Connection Error</h3>
            <p>It looks like you've been disconnected from the remote server. Press the button below to reload the app if this warning doesn't disappear automatically after a few seconds.</p>
            <Button onClick={ reload }>Reload</Button>
        </div>
    );

};

export default ConnectionWarning;
