// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import uuid from 'uuid/v4';

// Styles.
import './CreateTeeTime.scss';

// Components.
import Input from '../../components/Input';
import Button from '../../components/Button';

// Utils.
import { getRoundsForGolfer, getHandicapForGolfer } from '../../utils/golf';

// Firebase.
import { createTeeTime } from '../../firebase/db';

class CreateTeeTime extends React.Component {

    static get propTypes() {
        return {
            setPageTitle: PropTypes.func.isRequired,
            setLoading: PropTypes.func.isRequired,
            user: PropTypes.object,
            rounds: PropTypes.object,
            golfers: PropTypes.object,
            settings: PropTypes.object,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            rounds: null,
            golfers: null,
            settings: null,
        }
    }

    state = {
        golfer1: '',
        golfer2: '',
        golfer3: '',
        golfer4: '',
        teeTime: '',
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('New Tee Time');
    }

    round = uid => {
        return this.props.rounds ? this.props.rounds[uid] : null;
    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value });
    }

    filterGolfers = excludedGolfers => {

        const round = this.round(this.props.match.params.round_uid);

        // No tee times have been created yet so
        // just check the current inputs.
        if (!round.teeTimes) return excludedGolfers;

        const golfersAlreadyPlaying = Object.values(round.teeTimes).map(teeTime => (
            Object.keys(teeTime.golfers)
        )).flat();

        return golfersAlreadyPlaying.concat(excludedGolfers);

    }

    isReady = () => {
        return (
            (
                this.state.golfer1 !== '' ||
                this.state.golfer2 !== '' ||
                this.state.golfer3 !== '' ||
                this.state.golfer4 !== ''
            ) &&
            this.state.teeTime !== ''
        );
    }

    saveTeeTime = e => {

        e.preventDefault();

        this.props.setLoading(true);

        const { settings, golfers, rounds } = this.props;
        const { golfer1, golfer2, golfer3, golfer4 } = this.state;

        let teeTime = {};
        let order = 0;

        for (let golfer of [golfer1, golfer2, golfer3, golfer4]) {
            if (golfer) {
                teeTime[golfer] = { handicap: golfers[golfer].givenHandicap, order: ++order };
            }
        }

        for (let golfer of [golfer1, golfer2, golfer3, golfer4]) {

            const roundsPlayed = getRoundsForGolfer(rounds, golfer, true).length;

            if (golfer && settings.useCalculatedHandicap && roundsPlayed >= 5) {

                const handicap = getHandicapForGolfer(rounds, golfer);

                if (handicap) {
                    teeTime[golfer].handicap = Math.floor(handicap);
                }

            }

        }

        createTeeTime(this.props.user, uuid(), this.props.match.params.round_uid, teeTime, this.state.teeTime).then(() => {
            this.props.setLoading(false);
            this.props.history.push(`/play/rounds/${ this.props.match.params.round_uid }`);
        });

    }

    render() {

        const { user, rounds, golfers, settings } = this.props;
        const { golfer1, golfer2, golfer3, golfer4, teeTime } = this.state;

        if (!user || !rounds || !golfers || !settings) return <Redirect to="/" />;

        const round = this.round(this.props.match.params.round_uid);

        // Bail if a user tries to hack the URL.
        if (round.complete) return <Redirect to="/" />;

        return (
            <section className="CreateTeeTime">
                <form onSubmit={ this.saveTeeTime }>
                    <Input
                        type="time"
                        header="Tee Time"
                        value={ teeTime }
                        onChange={ e => this.onChange(e, 'teeTime') }
                    />
                    <Input
                        type="select"
                        options={[
                            { label: '-', value: '' },
                            ...Object.entries(golfers).filter(golfer => !(this.filterGolfers([golfer2, golfer3, golfer4]).includes(golfer[0]))).map((golfer) => (
                                { label: golfer[1].name, value: golfer[0] }
                            ))
                        ]}
                        header="Golfer 1"
                        value={ golfer1 }
                        onChange={ e => this.onChange(e, 'golfer1') }
                    />
                    <Input
                        type="select"
                        options={[
                            { label: '-', value: '' },
                            ...Object.entries(golfers).filter(golfer => !(this.filterGolfers([golfer1, golfer3, golfer4]).includes(golfer[0]))).map((golfer) => (
                                { label: golfer[1].name, value: golfer[0] }
                            ))
                        ]}
                        header="Golfer 2"
                        value={ golfer2 }
                        onChange={ e => this.onChange(e, 'golfer2') }
                    />
                    <Input
                        type="select"
                        options={[
                            { label: '-', value: '' },
                            ...Object.entries(golfers).filter(golfer => !(this.filterGolfers([golfer1, golfer2, golfer4]).includes(golfer[0]))).map((golfer) => (
                                { label: golfer[1].name, value: golfer[0] }
                            ))
                        ]}
                        header="Golfer 3"
                        value={ golfer3 }
                        onChange={ e => this.onChange(e, 'golfer3') }
                    />
                    <Input
                        type="select"
                        options={[
                            { label: '-', value: '' },
                            ...Object.entries(golfers).filter(golfer => !(this.filterGolfers([golfer1, golfer2, golfer3]).includes(golfer[0]))).map((golfer) => (
                                { label: golfer[1].name, value: golfer[0] }
                            ))
                        ]}
                        header="Golfer 4"
                        value={ golfer4 }
                        onChange={ e => this.onChange(e, 'golfer4') }
                    />
                    <Button disabled={ !this.isReady() }>Save</Button>
                </form>
            </section>
        );

    }

}

export default CreateTeeTime;
