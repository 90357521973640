import CreateTeeTime from './CreateTeeTime';
import { connect } from 'react-redux';
import { setPageTitle, setLoading } from '../../redux/actions/app';

const mapStateToProps = state => ({
    user: state.app.user,
    rounds: state.app.rounds,
    golfers: state.app.golfers,
    settings: state.app.settings,
});

const mapDispatchToProps = {
    setPageTitle,
    setLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeeTime);
