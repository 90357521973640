// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Styles.
import './CreateTeeColor.scss';

// Components.
import Input from '../../components/Input';
import Button from '../../components/Button';
import CreateCourseHeader from '../../components/CreateCourseHeader';
import HoleRow from '../../components/HoleRow';

// Firebase.
import { createCourse } from '../../firebase/db';

class CreateTeeColor extends React.Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            courses: PropTypes.object,
            setLoading: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            courses: null,
        }
    }

    state = {
        teeColor: '',
        slope: '',
        rating: '',
        holes: {},
    };

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('New Tee Color');
    }

    course = uid => {
        return this.props.courses ? this.props.courses[uid] : null;
    }

    onChange = (e, id) => {
        this.setState({ [id]: e.target.value });
    }

    onHoleChange = (hole, par, yardage, strokeIndex) => {
        this.setState({
            holes: {
                ...this.state.holes,
                [hole]: { par, yardage, strokeIndex },
            }
        });
    }

    save = e => {

        e.preventDefault();
        this.props.setLoading(true);

        const teeColor = this.state.teeColor.trim();
        const slope = parseInt(this.state.slope);
        const rating = parseFloat(this.state.rating);
        const { course_uid } = this.props.match.params;
        const course = this.course(course_uid);

        let holes = this.state.holes;

        Object.keys(holes).forEach(key => {
            holes[key].par = parseInt(holes[key].par);
            holes[key].yardage = parseInt(holes[key].yardage);
            holes[key].strokeIndex = parseInt(holes[key].strokeIndex);
        });

        createCourse(this.props.user, course_uid, {
            ...course,
            tees: {
                ...course.tees,
                [teeColor]: {
                    holes,
                    slope,
                    rating,
                },
            },
        }).then(() => {
            this.props.setLoading(false);
            this.props.history.push(`/courses/${ course_uid }`);
        });

    }

    isReady = () => {

        let ready = true;

        for (let hole of Object.values(this.state.holes)) {
            ready = ((parseInt(hole.par) > 0) && (parseInt(hole.yardage) > 0) && (parseInt(hole.strokeIndex) > 0));
            if (!ready) break;
        }

        const slope = parseInt(this.state.slope);
        const rating = parseFloat(this.state.rating);

        // Validate the slope.
        if (!(slope >= 55 && slope <= 155)) {
            ready = false;
        }

        // Validate the rating.
        if (!(rating >= 1 && rating <= 100)) {
            ready = false;
        }

        const strokeIndexes = [];

        for (let hole of Object.values(this.state.holes)) {
            strokeIndexes.push(hole.strokeIndex);
        }

        const distinctStrokeIndexes = [...new Set(strokeIndexes)];

        // Validate stroke indexes (for uniqueness).
        if (distinctStrokeIndexes.length !== 18) {
            ready = false;
        }

        return (
            this.state.teeColor !== '' &&
            this.state.slope !== '' &&
            this.state.rating !== '' &&
            Object.keys(this.state.holes).length === 18 &&
            ready
        );

    }

    render() {

        const { teeColor, slope, rating, holes } = this.state;
        const { user, match } = this.props;
        const course = this.course(match.params.course_uid);

        if (!user || !course) return <Redirect to="/courses" />;

        return (
            <div className="CreateTeeColor">
                <form onSubmit={ this.save }>
                    <Input type="text" header="Tee Color" value={ teeColor } onChange={ e => this.onChange(e, 'teeColor') } />
                    <Input type="number" header="Slope" value={ slope } onChange={ e => this.onChange(e, 'slope') } />
                    <Input type="number" header="Rating" value={ rating } onChange={ e => this.onChange(e, 'rating') } />
                    <CreateCourseHeader />
                    {
                        Array(18).fill().map((_, i) => (
                            <HoleRow
                                hole={ i + 1 }
                                onHoleChange={ this.onHoleChange }
                                par={ (holes[i + 1] && parseInt(holes[i + 1].par)) || 0 }
                                yardage={ (holes[i + 1] && holes[i + 1].yardage) || '' }
                                strokeIndex={ (holes[i + 1] && parseInt(holes[i + 1].strokeIndex)) || 0 }
                                key={ i }
                            />
                        ))
                    }
                    <Button disabled={ !this.isReady() }>Save</Button>
                </form>
            </div>
        );

    }

}

export default CreateTeeColor;
