import Round from './Round';
import { connect } from 'react-redux';
import { setModalContent, setPageTitle } from '../../redux/actions/app';

const mapStateToProps = state => ({
    user: state.app.user,
    rounds: state.app.rounds,
    golfers: state.app.golfers,
    settings: state.app.settings,
});

const mapDispatchToProps = {
    setModalContent,
    setPageTitle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Round);
