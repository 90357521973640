// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Components.
import CourseRow from '../CourseRow';
import Notification from '../Notification';

// Utils.
import { getGrossScoreForRound, formatScoreToPar, getCoursePar } from '../../utils/golf';

const RoundList = ({ rounds, golferId, search }) => {

    let list = Object.entries(rounds);

    if (search !== '') {
        list = list.filter(round => {

            const name = round[1].course.details.name.toLowerCase();
            const date = moment.unix(round[1].date / 1000).format('dddd, MMMM D, YYYY').toLowerCase();
            const s = search.toLowerCase().trim();

            return (name.includes(s) || date.includes(s));

        });
    }

    return (
        <div className="RoundList">
            {
                (list.filter(round => round[1].complete).length === 0) &&
                <Notification>No rounds.</Notification>
            }
            {
                list.filter(round => round[1].complete).sort((a, b) => (b[1].date - a[1].date)).map((round, key) => {

                    let score = null;

                    if (golferId) {
                        const gross = getGrossScoreForRound(round[1], golferId);
                        score = (round[1].format === 'scramble') ? formatScoreToPar(gross - getCoursePar(round[1].course.details, round[1].teeColor)) : gross;
                    }

                    return (
                        <Link to={ `/rounds/${ round[0] }` } key={ key }>
                            <CourseRow
                                primary={ round[1].course.details.name }
                                secondary={ moment.unix(round[1].date / 1000).format('dddd, MMMM D, YYYY') }
                                tertiary={ score }
                            />
                        </Link>
                    );

                })
            }
        </div>
    );

};

RoundList.propTypes = {
    rounds: PropTypes.object,
    golferId: PropTypes.string,
    search: PropTypes.string,
};

RoundList.defaultProps = {
    rounds: null,
    golferId: null,
    search: '',
};

export default RoundList;
