// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles.
import './NavBar.scss';

const NavBar = ({ isMenuOpen, toggleMenu, pageTitle }) => (
    <nav className={ `NavBar ${ isMenuOpen && 'open' }` }>
        <Link to="/"><h1 className="title-treatment">C{ !pageTitle && 'hili Dip' }<span>.</span></h1></Link>
        { pageTitle && !isMenuOpen && <h2>{ pageTitle }</h2> }
        { isMenuOpen && <h2>Settings</h2> }
        <div className={ `hamburglar ${ isMenuOpen && 'close' }` } onClick={ toggleMenu }>
            <div className="patty" />
            <div className="patty" />
            <div className="patty" />
        </div>
    </nav>
);

NavBar.propTypes = {
    isMenuOpen: PropTypes.bool,
    toggleMenu: PropTypes.func.isRequired,
    pageTitle: PropTypes.string,
};

NavBar.defaultProps = {
    isMenuOpen: false,
    pageTitle: null,
};

export default NavBar;
