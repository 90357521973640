import { database } from './config';

export const getConnectionStatus = () => {
    return database.ref(`.info/connected`);
};

export const getUser = user => {
    return database.ref(`users/${ user.uid }`).once('value');
};

export const createUser = user => {
    return database.ref(`users/${ user.uid }`).set({
        settings: {
            useCalculatedHandicap: 0,
            useScorecardShapes: 0,
        },
    });
};

export const getSettings = user => {
    return database.ref(`users/${ user.uid }/settings`);
};

export const updateSettings = (user, settings) => {
    return database.ref(`users/${ user.uid }/settings`).set(settings);
};

export const createGolfer = (user, uuid, golfer) => {
    return database.ref(`users/${ user.uid }/golfers/${ uuid }`).set(golfer);
}

export const getGolfers = user => {
    return database.ref(`users/${ user.uid }/golfers`).orderByChild('name');
};

export const createCourse = (user, uuid, course) => {
    return database.ref(`users/${ user.uid }/courses/${ uuid }`).set(course);
}

export const getCourses = user => {
    return database.ref(`users/${ user.uid }/courses`).orderByChild('name');
};

export const createTournament = (user, uuid, tournament) => {
    return database.ref(`users/${ user.uid }/tournaments/${ uuid }`).set({ ...tournament, complete: 0 });
}

export const getTournaments = user => {
    return database.ref(`users/${ user.uid }/tournaments`).orderByChild('name');
};

export const createRound = (user, uuid, round) => {
    return database.ref(`users/${ user.uid }/rounds/${ uuid }`).set({ ...round, complete: 0 });
};

export const getRounds = user => {
    return database.ref(`users/${ user.uid }/rounds`).orderByChild('date');
};

export const createTeeTime = (user, uuid, roundId, teeTime, startTime) => {
    return database.ref(`users/${ user.uid }/rounds/${ roundId }/teeTimes/${ uuid }`).set({
        golfers: teeTime,
        complete: 0,
        startTime,
    });
};

export const createMatch = (user, uuid, roundId, match) => {
    return database.ref(`users/${ user.uid }/rounds/${ roundId }/matches/${ uuid }`).set({
        ...match,
        date: new Date().getTime(),
    });
};

export const setScore = (user, roundId, teeTimeId, golferId, hole, score) => {
    return database.ref(`users/${ user.uid }/rounds/${ roundId }/teeTimes/${ teeTimeId }/golfers/${ golferId }/scores/${ hole }`).set(score);
};

export const signScorecard = (user, roundId, teeTimeId) => {
    return database.ref(`users/${ user.uid }/rounds/${ roundId }/teeTimes/${ teeTimeId }/complete`).set(1);
};

export const completeRound = (user, roundId) => {
    return database.ref(`users/${ user.uid }/rounds/${ roundId }/complete`).set(1);
};

export const completeTournament = (user, tournamentId) => {
    return database.ref(`users/${ user.uid }/tournaments/${ tournamentId }/complete`).set(1);
};
