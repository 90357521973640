// Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Components.
import Leaderboard from '../../components/Leaderboard';
import Scorecard from '../../components/Scorecard';

import Tab from '../../components/Tab';

// Styles.
import './TeeTime.scss';

class TeeTime extends Component {

    static get propTypes() {
        return {
            user: PropTypes.object,
            rounds: PropTypes.object,
            golfers: PropTypes.object,
            courses: PropTypes.object,
            settings: PropTypes.object,
            setLoading: PropTypes.func.isRequired,
            setPageTitle: PropTypes.func.isRequired,
            setModalContent: PropTypes.func.isRequired,
        }
    }

    static get defaultProps() {
        return {
            user: null,
            rounds: null,
            golfers: null,
            courses: null,
            settings: null,
        }
    }

    state = {
        teeTimeTab: 'left',
    }

    componentDidMount() {
        document.getElementById('routeWrapper').scrollTo(0, 0);
        this.props.setPageTitle('Tee Time');
    }

    teeTime = (roundId, teeTimeId) => (
        this.props.rounds ? this.props.rounds[roundId].teeTimes[teeTimeId] : null
    )

    round = roundId => (
        this.props.rounds ? this.props.rounds[roundId] : null
    )

    scorecardSelected = () => {
        this.setState({ teeTimeTab: 'left' });
        document.getElementById('routeWrapper').scrollTo(0, 0);
    }

    leaderboardSelected = () => {
        this.setState({ teeTimeTab: 'right' });
        document.getElementById('routeWrapper').scrollTo(0, 0);
    }

    render() {

        const { teeTimeTab } = this.state;
        const { user, rounds, golfers, courses, settings } = this.props;

        if (!user || !rounds || !golfers || !courses || !settings) return <Redirect to="/" />;

        const teeTime = this.teeTime(this.props.match.params.round_uid, this.props.match.params.teetime_uid);

        // Bail if a user tries to hack the URL.
        if (teeTime.complete) return <Redirect to="/" />;

        return (
            <section className="TeeTime">
                <div className="tabWrapper">
                    <Tab
                        titles={{
                            left: 'Scorecard',
                            right: 'Leaderboard',
                        }}
                        toggleLeft={ this.scorecardSelected }
                        toggleRight={ this.leaderboardSelected }
                        selected={ teeTimeTab }
                    />
                </div>
                {
                    teeTimeTab === 'left' &&
                    <Scorecard
                        user={ user }
                        teeTime={ teeTime }
                        golfers={ golfers }
                        round={ this.round(this.props.match.params.round_uid) }
                        roundId={ this.props.match.params.round_uid }
                        teeTimeId={ this.props.match.params.teetime_uid }
                        settings={ settings }
                    />
                }
                {
                    teeTimeTab === 'right' &&
                    <Leaderboard
                        golfers={ golfers }
                        round={ this.round(this.props.match.params.round_uid) }
                        roundId={ this.props.match.params.round_uid }
                        rounds={ rounds }
                        setModalContent={ this.props.setModalContent }
                    />
                }
            </section>
        );

    }

}

export default TeeTime;
